import * as Bluebird from 'bluebird';
import { ValueOf } from '@powerednow/type-definitions';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import ActionEntity from './entity';
import modelProperties from './modelProperties';
import Company from '../company';
import Job from '../job';
import ContactToAddress from '../contactToAddress';
import UserAction from '../userAction';
import User from '../user';
import Message from '../message';
import CompanyEntity from '../company/entity';
import JobEntity from '../job/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import UserActionEntity from '../userAction/entity';
import MessageEntity from '../message/entity';
import Customer from '../customer';
import { CustomerMessageLinkedRecord } from '../interfaces';
import { ACTION, SETTINGS_KEYS } from '../../../constants';
import {
    addPlusHours, format, getPartOfDayNameByKey, patterns, 
} from '../../utilities/date';
import Contact from '../contact';
import { ModelFields } from '../entity';
import { CustomerNotificationGroups } from '../../../constants/notificationDefinition';
import { ChannelTypes } from '../../../constants/customerEmailTemplateValues';
import converterUtils from '../../utilities/converter';

interface ActionAssociations extends AssociationConfig<any, any> {
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    job: AssociationDefinitionSingle<JobEntity, Job>
    site: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    userAction: AssociationDefinition<UserActionEntity, UserAction>
    message: AssociationDefinition<MessageEntity, Message>
}

interface Action extends AutoGeneratedFunctions<ActionAssociations, ActionEntity, ComplexData<ActionEntity>> {
}

// eslint-disable-next-line no-redeclare
class Action extends ComplexData<ActionEntity> implements CustomerMessageLinkedRecord {
    static Entity = ActionEntity;

    _entity: ActionEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): ActionAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().job_id,
                },
            },
            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().site_id,
                },
            },
            userAction: {
                key: 'userAction',
                instance: UserAction,
                entity: UserActionEntity,
                cascadeDelete: true,
                condition: {
                    action_id: this.Entity.getFieldSymbols().id,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                cascadeDelete: true,
                condition: {
                    action_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    public async getCustomer(): Promise<Customer | null> {
        const job = await this.getJob();
        if (!job) {
            return null;
        }
        return job.getCustomer();
    }

    async findContactableSite(contactChannels: ChannelTypes[]): Promise<ContactToAddress> {
        if (this.data.site_id) {
            const site = await this.getSite();
            const isContactable = await site.isContactable(contactChannels);
            if (isContactable) {
                return site;
            }
        }

        const job = await this.getJob();
        if (job) {
            const jobSite = job ? await job.findSite() : null;
            if (jobSite && (await jobSite.isContactable(contactChannels))) {
                return jobSite;
            }
            const customer = await job.getCustomer();
            const mainSite = await customer.getMainContactToAddress();

            if (mainSite && (await mainSite.isContactable(contactChannels))) {
                return mainSite;
            }
        }
        return null;
    }

    async getNotificationContact(): Promise<Contact[]> {
        const company = await this.getCompany();
        const settingValue = await company.getSettingValue(SETTINGS_KEYS.CUSTOMER_NOTIFICATION_CONTACT);
        const setting = converterUtils.toNumberIfPossible(settingValue);
        const sites: Contact[] = [];
        if (setting === CustomerNotificationGroups.SiteContact.id || setting === CustomerNotificationGroups.Both.id) {
            const channels = await company.getNotificationChannels();
            const contactableSite = await this.findContactableSite(channels);
            if (contactableSite) {
                sites.push(await contactableSite.getContact());
            }
        }
        //
        // If setting is main contact (so we don't go into the above block) or we go into that but
        // didn't find anything or we just need both then get main contact as well
        //
        if (sites.length === 0 || setting === CustomerNotificationGroups.Both.id) {
            const customer = await this.getCustomer();
            if (customer) {
                sites.push(await customer.getMainContact());
            }
        }

        const filterSites = sites.filter(Boolean);

        // Remove duplicate contacts
        const uniqueSites = filterSites.filter((value, index, self) => self.findIndex(contact => contact.data.id === value.data.id) === index);

        return uniqueSites;
    }

    async findJobSite() {
        return (await this.getJob()).findSite();
    }

    async findSite() {
        const siteId = this.data.site_id;
        if (siteId) {
            return this.getSite();
        }
        return (await this.getJob()).findSite();
    }

    public async getAssignedUsers(): Promise<User[]> {
        return Bluebird.reduce(this.getAllUserAction(), async (users, userAction) => {
            const complexUser = await userAction.getUser();
            if (complexUser) {
                users.push(complexUser);
            }
            return users;
        }, []);
    }

    getPlanTypeObject() {
        return Object.values(ACTION.PLAN_TYPES).filter(planType => planType.ID === this.data.plan_type)[0];
    }

    async assignedUserNames() {
        const assignedUsers = await this.getAssignedUsers();
        return assignedUsers.map(user => user.getFullName());
    }

    async getTemplateData(): Promise<ModelFields<ActionEntity> & {
        jobDescription: string,
        durationType: string,
        dateTimeFormat: string,
        projectName: string,
        customerName: string,
        fullname: string,
    }> {
        //
        // In case of deleted actions the plan type might not be available anymore
        //
        const dateTimeFormat = this.getPlanTypeObject().FORMAT_OPTIONS || ACTION.PLAN_TYPES.DATE.FORMAT_OPTIONS;
        const company = await this.getCompany();
        const job = await this.getJob();

        const projectName = job?.data.description;
        const customer = await this.getCustomer();
        const customerName = await customer?.getFullName();

        const jobDescription = await company.getSettingValue(SETTINGS_KEYS.SERVICE_PERSON) as string;
        const formattedDurationType = (ACTION.APPOINTMENT_DURATION_TEXT[this.data.duration_id] ? `${ACTION.APPOINTMENT_DURATION_TEXT[this.data.duration_id]} of ` : '');

        return {
            ...this.data.getPureDataValues(),
            jobDescription,
            durationType: formattedDurationType,
            dateTimeFormat,
            projectName,
            customerName,
            fullname: (await this.assignedUserNames()).join(', '),
        };
    }

    static calculateDurationForBooking({
        day, dayPart, workday_start, workday_end, lunchTime, workdayHalfTime, eveningHours,
    }) {
        const hours = {
            [ACTION.APPOINTMENT_DURATION.WORKING_DAYS]: {
                start: workday_start || ACTION.DEFAULT_TIME.WORKDAY_START,
                end: workday_end || ACTION.DEFAULT_TIME.WORKDAY_END,
                sameDay: true,
            },
            [ACTION.APPOINTMENT_DURATION.MORNING]: {
                start: workday_start || ACTION.DEFAULT_TIME.WORKDAY_START,
                end: lunchTime.enabled ? lunchTime.start : workdayHalfTime,
                sameDay: true,
            },
            [ACTION.APPOINTMENT_DURATION.AFTERNOON]: {
                start: lunchTime.enabled ? lunchTime.end : workdayHalfTime,
                end: workday_end || ACTION.DEFAULT_TIME.WORKDAY_END,
                sameDay: true,
            },
            [ACTION.APPOINTMENT_DURATION.EVENING]: {
                start: eveningHours.enabled ? eveningHours.start : addPlusHours(workday_end, 1),
                end: eveningHours.enabled ? eveningHours.end : addPlusHours(workday_end, 4),
                sameDay: true,
            },
        };

        const start = new Date(day);
        const end = new Date(day);

        const dayPartId: ValueOf<typeof ACTION.APPOINTMENT_DURATION> = dayPart === 'allDay' ? ACTION.APPOINTMENT_DURATION.WORKING_DAYS : ACTION.APPOINTMENT_DURATION[getPartOfDayNameByKey(dayPart).toUpperCase()];
        const [startHour, startMinute] = hours[dayPartId].start.split(':');
        const [endHour, endMinute] = hours[dayPartId].end.split(':');

        start.setHours(startHour);
        start.setMinutes(startMinute);
        end.setHours(endHour);
        end.setMinutes(endMinute);
        return { start, end, dayPartId };
    }
}

export default Action;
