// eslint-disable-next-line max-classes-per-file
import * as Bluebird from 'bluebird';
import * as FilterBuilder from '@powerednow/shared/modules/utilities/filterBuilder';
import { hasMixin } from 'ts-mixer';
import {
    Newable, This, PropType,
} from '@powerednow/type-definitions';
import {
    getValuesIncludingSymbolProperties,
    entriesWithSymbols,
    getGetterReference,
} from '@powerednow/shared/modules/utilities/object';
import BaseData from './baseData';
import * as exceptions from '../errors';
import ValidationResult from '../validation/validationResult';
import ModelValidator from '../validation/modelValidator';
import ConnectedData, { Filter, GetAssociatedOptions, Sorter } from './connectedData';
import Cache from './cache';
import Entity, {
    ComplexModelFields,
    DataObject,
    EntityWithId,
    FieldDefinition,
    ModelFields, PartialModelFields,
} from './entity';
import UniqueIdStorage from './uniqueIdStorage';
import { memoizeRunningPromiseByKey, setMemoizeByInstance, memoize } from '../../decorators';
import { skipAddToCacheOnConstruct } from './_decorator';

const _ = require('lodash');

const instanceSymbol: symbol = Symbol('instanceSymbol');
const itemIsInstantiatedSymbol: symbol = Symbol('itemIsInstantiated');
const constructorSymbol: symbol = Symbol('constructorSymbol');
const isNewSymbol = Symbol('isNewSymbol');
const isDeletedSymbol = Symbol('isDeletedSymbol');
const isUpdatedSymbol = Symbol('isUpdatedSymbol');
const skipDefaultsSymbol = Symbol('skipDefaults');

const valuesAsArrayKey = Symbol('valuesAsArrayKey');

export type FieldMap<E extends EntityWithId> = {
    [K in keyof Partial<ModelFields<E>>]?: E[K] | symbol;
};

export type AssociatedFieldMap<E extends EntityWithId> = {
    [K in keyof Partial<ModelFields<E>>]: symbol | string;
};

export type ForeignKeyDefinition = {
    model: string;
    associatedModel: string;
    associatedField: AssociatedFieldMap<any>;
    condition: FieldMap<any>;
};

export type SingleAssociationConfig = {
    lookupKey: string;
    // eslint-disable-next-line no-use-before-define
    ItemConstructor: Newable<ComplexData<any>>;
    conditions: any;
    isSingle: boolean;
    foreignOptions: any;
    methodName: string;
}

type LogicalHointFieldMap<E extends EntityWithId> = {
    $or: FieldMap<E>[]
}

// eslint-disable-next-line no-use-before-define
export interface AssociationDefinition<E extends EntityWithId, T extends ComplexData<E>> {
    key: string;
    instance: Newable<T>;
    entity: Newable<E>;
    cascadeDelete?: boolean;
    via?: ForeignKeyDefinition;
    condition?: FieldMap<E> | LogicalHointFieldMap<E>;
}

// eslint-disable-next-line no-use-before-define
export interface AssociationDefinitionSingle<E extends EntityWithId, T extends ComplexData<E>> extends AssociationDefinition<E, T> {
    single: boolean;
}

// eslint-disable-next-line no-use-before-define
export interface AssociationConfig<T extends ComplexData<any>, U extends AssociationDefinition<any, T>> {
    [propName: string]: U;
}

export type MethodDefinition = {
    prefix: string;
    postfix?: string;
    isPlural: boolean;
    isSingle: boolean;
    noInstanceNeeded?: boolean;
    handler: Function;
};

export type FieldChangeEventHandlers = {
    [propName: string]: (newValue?: any, oldValue?: any) => void,
};

export type AssociationNameToComplexDataArray = {
    [assocNameName: string]: {
        [id: number | symbol]: ComplexData<any>,
    },
};

//
// This seems to be a bit far too complicated. But due to TS 4.4 changes we need an extra level here.
// For details please see https://github.com/microsoft/TypeScript/issues/45548
//
export type AutoGeneratedFunctions<T extends AssociationConfig<any, any>, E extends EntityWithId, U extends ComplexData<E>> = {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? `get${Capitalize<string & K>}` : never]:
    () => Promise<InstanceType<T[K]['instance']>>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `get${Capitalize<string & K>}`]:
    (_index: number) => Promise<InstanceType<T[K]['instance']>>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `getAll${Capitalize<string & K>}`]:
    (_options?: GetAssociatedOptions<PropType<InstanceType<T[K]['instance']>, '_entity'>>) => Promise<InstanceType<T[K]['instance']>[]>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `get${Capitalize<string & K>}ById`]:
    (_id: number) => Promise<InstanceType<T[K]['instance']>>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `get${Capitalize<string & K>}Count`]:
    (_filters?: Filter<PropType<InstanceType<T[K]['instance']>, '_entity'>>[]) => Promise<number>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `add${Capitalize<string & K>}`]:
    (_value: InstanceType<T[K]['instance']> | Partial<ComplexModelFields<InstanceType<T[K]['entity']>>>) => Promise<InstanceType<T[K]['instance']>>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `addAll${Capitalize<string & K>}`]:
    (_value: [InstanceType<T[K]['instance']> | Partial<ComplexModelFields<InstanceType<T[K]['entity']>>>][]) => Promise<InstanceType<T[K]['instance']>[]>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? `set${Capitalize<string & K>}` : never]:
    (_value: InstanceType<T[K]['instance']> | Partial<ComplexModelFields<InstanceType<T[K]['entity']>>>) => Promise<InstanceType<T[K]['instance']>>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? `unset${Capitalize<string & K>}` : never]:
    () => Promise<void>
    } & {
        [K in keyof T as string extends K ?
        never : T[K] extends AssociationDefinitionSingle<any, any> ? never : `remove${Capitalize<string & K>}`]:
    (_value: InstanceType<T[K]['instance']> | Partial<ComplexModelFields<InstanceType<T[K]['entity']>>>) => Promise<void>
    }

export function defineDynamicClass<T extends AssociationConfig<any, any>, E extends EntityWithId, U extends ComplexData<E> = ComplexData<E>>(originalClass: Newable<U>): AutoGeneratedFunctions<T, E, U> & U {
    return originalClass as any;
}

/**
 * This is kinda a decorator, but actually it is not a real one.
 * The problem is the legacy decorator spec we depend on doesn't support getter/setter decorators.
 * The new proposed decorator spec is not compatible with the legacy one and far, far, _far_ less powerful.
 * What we do here basically is to create a lazy getter on the scope, grab the original getter reference and when called
 * we store the result from the original getter on the calling scope.
 *
 * It is important to scan through the prototype chain for getters, as we may override specific getters in a child,
 * but it is also important to ignore any of our memoized getters when searching for original getter, as we may
 * already cached it, before we created a new subclass, with different getter.
 *
 * @param scope
 * @param functionName
 */

const memoizedScopes = new Set();
const memoizeGetter = (scope, functionName) => {
    const originalGetter = getGetterReference(scope, functionName, reference => !reference.isMemoized);
    if (memoizedScopes.has(scope)) {
        return;
    }
    memoizedScopes.add(scope);
    let original = null;
    function get() {
        if (!original) {
            original = originalGetter.call(scope);
        }
        return original;
    }
    get.isMemoized = true;
    Object.defineProperty(scope, functionName, {
        get,
    });
};

export class ComplexDataError extends Error {
    public details: Entity;

    constructor(message, protected complexInstance?: ComplexData<any>) {
        super(message);
        this.details = this.complexInstance.getData();
    }
}

export class DisposableComplexDataError extends ComplexDataError {
    constructor(message: string, protected complexInstance?: ComplexData<any>) {
        super(message, complexInstance);
    }
}

const doNotFilter = () => true;
const ARRAY_PROTOTYPE_KEYS = Reflect.ownKeys(Object.getOwnPropertyDescriptors(Array.prototype));
const PROMISE_PROTOTYPE_KEYS = Reflect.ownKeys(Object.getOwnPropertyDescriptors(Promise.prototype));
const PROXY_NON_ENUMERABLE_KEYS = new Set([...ARRAY_PROTOTYPE_KEYS, ...PROMISE_PROTOTYPE_KEYS]);

export default class ComplexData<T extends EntityWithId> extends ConnectedData<T> {
    private static associationsProcessed = false;

    declare ['constructor']: typeof ComplexData;

    public static valuesAsArrayKey = valuesAsArrayKey;

    public associatedValues: Record<string, any[]> = {};

    private associatedValueLoadingOptions: Record<string, Record<string, boolean>> = {};

    public deletedAssociatedItems = [];

    private loadedAssociationValuesKeys = {};

    protected extraData: DataObject;

    private joinTables: DataObject = {};

    private associationToJoinTable: DataObject = {};

    private proxyMap = {};

    private proxyMapKey: symbol;

    private deletedItemsHolderForClone = [];

    private initialId: symbol | number = null;

    private conditionalKeys = new Set();

    private saving = null;

    // This is kinda a hack, used only during instantiation
    private _parent;

    private static [skipAddToCacheOnConstruct] = false;

    public static get ERRORS() {
        return {};
    }

    private static runningBuildPromises = new Map();

    public static async getAll<A extends EntityWithId, C extends ComplexData<A>, U extends This<Newable<C>>>(this: U, complexObject: ComplexData<any>): Promise<InstanceType<U>[]> {
        const castedThis = this as U & typeof ComplexData;
        const response = await complexObject.loadGenericData(<typeof ConnectedData>castedThis);
        const responseData = response && response.responseData;
        return Bluebird.map(responseData, data => castedThis.build(data, {}, {
            listeners: complexObject.getRootListenerMap(BaseData.EXTERNAL_EVENTS),
            cache: complexObject.cache,
        }));
    }

    public static async build<A extends EntityWithId, C extends ComplexData<A>, U extends This<Newable<C>>>(
        this: U,
        data: PartialModelFields<A>,
        extraData: DataObject = {},
        {
            listeners = {}, parent = null, cache = null, disposable = false,
        }: {
            parent?: ComplexData<any>,
            listeners: { string?: Function },
            cache?: Cache,
            disposable?: boolean
        } = {
            parent: null,
            listeners: {},
            cache: null,
            disposable: false,
        },
    ): Promise<InstanceType<U>> {
        const castedThis = this as U & typeof ComplexData;
        if (!cache) {
            return castedThis.buildInstance(data, extraData, {
                parent, listeners, cache, disposable,
            });
        }
        const storeName = castedThis.modelDefinition.modelName;
        const { id } = data as unknown as EntityWithId;
        const cached = cache.get(storeName, id);
        if (cached) {
            return cached as InstanceType<U>;
        }
        const keyId = id !== null && typeof id === 'symbol' ? (id as any).toString() : id;
        const runningPromiseKey = `${storeName}-${keyId}`;
        let runningPromise = castedThis.runningBuildPromises.get(runningPromiseKey);
        if (!runningPromise) {
            runningPromise = castedThis.buildInstance(data, extraData, {
                parent, listeners, cache, disposable,
            });
            if (id !== null) {
                castedThis.runningBuildPromises.set(runningPromiseKey, runningPromise);
            }
        }
        return runningPromise
            //  .finally polyfill
            .then(result => {
                castedThis.runningBuildPromises.delete(runningPromiseKey);
                return result;
            })
            .catch(error => {
                castedThis.runningBuildPromises.delete(runningPromiseKey);
                throw error;
            });
    }

    public static async buildInstance<A extends EntityWithId, C extends ComplexData<A>, U extends This<Newable<C>>>(
        this: U,
        data: PartialModelFields<A>,
        extraData: DataObject = {},
        {
            listeners = {}, parent = null, cache = null, disposable = false,
        }: {
            parent?: ComplexData<any>,
            listeners: { string?: Function },
            cache?: Cache,
            disposable?: boolean
        } = {
            parent: null,
            listeners: {},
            cache: null,
            disposable: false,
        },
    ): Promise<InstanceType<U>> {
        const instance = new this(data, extraData, {
            parent, listeners, cache, fromBuilder: true, disposable,
        });
        instance.deletedItemsHolderForClone = [];
        await instance.fillAssociatedValues(extraData, true, true);
        await instance.init();
        await Promise.all(instance.deletedItemsHolderForClone.map(deletedItem => deletedItem.delete()));
        return instance as InstanceType<U>;
    }

    protected async init() {
        //
        // This would be better placed in class BaseData. But in that case initNewItem
        // in the inherited classes couldn't access associations. So it is moved here.
        //
        if (this.isNewItem() && !this.shouldSkipDefaults()) {
            await this.initNewItem();
        }
    }

    private shouldSkipDefaults(): boolean {
        return Boolean(this.extraData[skipDefaultsSymbol]);
    }

    /**
     * Default constructor to create a new instance. As it is a singleton we
     * should make sure there is only one instance exists.
     *
     * @param {DataObject} data
     * @param {DataObject} extraData
     * @param {boolean} fromBuilder
     * @param {{string?: Function}} listeners
     * @param {ComplexData} parent
     * @param {Cache} cache
     */
    constructor(
        data: PartialModelFields<T>,
        extraData: DataObject = {},
        {
            fromBuilder = false, listeners = {}, parent = null, cache = null, disposable = false,
        }: {
            parent?: ComplexData<any>,
            fromBuilder?: boolean,
            listeners?: { string?: Function },
            cache?: Cache,
            disposable?: boolean,
        } = {
            parent: null,
            fromBuilder: false,
            listeners: {},
            cache: null,
            disposable: false,
        },
    ) {
        super(data, listeners, cache, disposable);
        /**
         * If you ever wonder why, then static initialization is kinda messed up with classes.
         * So we check this flag on the _current_ class, if there isn't one yet, lets set it.
         * There is a test for the issue caused if you remove this.
         */
        if (!Object.prototype.hasOwnProperty.call(this.constructor, 'associationsProcessed')) {
            this.constructor.associationsProcessed = false;
        }
        if (data[isUpdatedSymbol]) {
            this.data.isChanged = true;
        }

        if (data[isDeletedSymbol]) {
            this.privateDeleted = true;
        }

        this.extraData = extraData;

        if (!fromBuilder) {
            const isNewInstance = this.isNewItem();
            if (!disposable) {
                this.initAssociatedValues(isNewInstance);
            }
        }
        if (!this.constructor[skipAddToCacheOnConstruct]) {
            if (!this.disposable) {
                this.addEventHandlers();
            }

            if (parent) {
                parent.setupEventForwarding(this);
            }

            this.addToCache(this);
        }

        this._parent = parent;

        this.buildConditionalKeys();
    }

    /**
     * initialisation method for MixedClasses (using @complexDataMix decorator for applying mixins on a class)
     * currently the ts-mixer call every class's constructor on mixing classes, so it would add the original instance into the cache, not the MixedClass instance.
     * But we want the MixedClass instance in the cache with all the mixins applied on.
     *
     * So when the ts-mixer finished with all the classed mixing it will call this and we add the mixed instance to the cache here
     */
    public complexDataMixConstructor() {
        if (!this.disposable) {
            this.addEventHandlers();
        }

        if (this._parent) {
            this._parent.setupEventForwarding(this);
            this._parent = undefined;
        }

        this.processAssociations();
        this.addToCache(this);
    }

    private buildConditionalKeys() {
        Object.values(this.constructor.allowedAssociations).forEach(({ condition = {} }) => {
            Object.values(condition).forEach(conditionValue => {
                if (typeof conditionValue === 'symbol') {
                    this.conditionalKeys.add(this.constructor.Entity.fieldSymbolValues[conditionValue]);
                }
            });
        });
    }

    private initAssociatedValues(isNewInstance: boolean) {
        this.fillAssociatedValues(this.extraData, true, false);
        if (isNewInstance) {
            this.init();
        }
    }

    protected postCreate() {
        /**
         * Please read the comment for `complexDataMixConstructor` if unsure why we _need_ this.
         */
        if (!this.constructor[skipAddToCacheOnConstruct]) {
            this.processAssociations();
        }
    }

    public get reverseAssociations(): Set<ComplexData<any>> {
        if (this.cache) {
            return this.cache.reverseAssociations.get(this.constructor.getModelName(), this.data.id);
        }
        return new Set();
    }

    /**
     * Override this method in inherited classes
     * in order to add additional initialisation to newly created items
     * (e.g. add regular contact methods to a new contact)
     */
    protected async initNewItem(): Promise<void> {
        if (this.disposable) {
            return;
        }
        this.initDefaultValues();
        await this.initDefaultAssociatedItems();
    }

    // eslint-disable-next-line class-methods-use-this, no-empty-function
    protected async initDefaultAssociatedItems(): Promise<void> {

    }

    private getGeneratedAssociatedValueItems(associationName: string): DataObject[] {
        const items = this.getGeneratedAssociatedValueItemsObject(associationName);
        const foundItems = getValuesIncludingSymbolProperties(items, [valuesAsArrayKey]);
        const filteredItems = foundItems
            .filter(Boolean)
            .filter(item => (!item[instanceSymbol] || !item[instanceSymbol].isDeleted));
        //
        // replace items in array
        //
        items[valuesAsArrayKey].splice(0, items[valuesAsArrayKey].length, ...filteredItems);

        return items[valuesAsArrayKey];
    }

    protected initDefaultValues() {
        this.data.suppressEvents();
        this.constructor.Entity.fields.forEach((field: FieldDefinition) => {
            if (typeof field.defaultValue !== 'undefined' && typeof this.data[field.name] === 'undefined') {
                let { defaultValue } = field;
                if (typeof field.defaultValue === 'function') {
                    defaultValue = field.defaultValue();
                }
                // @ts-ignore
                this.data[field.name] = defaultValue;
            }
        });
        //
        // Data maybe will change in the event handler, so lets do this while the events are suppressed.
        //
        this.emit(ComplexData.EVENTS.MODEL_DATA_INITIALISED, this);
        this.data.unSuppressEvents();
    }

    private fillAssociatedValues(
        extraData: DataObject,
        isSync: boolean = false,
        fromBuilder: boolean = false,
        filter: (_T: any, _S: any, _C: any) => boolean = () => true,
    ) {
        const actualAssociations: Array<[string, AssociationDefinition<any, any>]> = Object.entries(this.constructor.allowedAssociations)
            .filter(filter);
        let isPromise = false;
        const associatedValues = actualAssociations.map(([key, associationDefinition]) => {
            const {
                ItemConstructor, lookupKey, conditions, foreignOptions,
            } = ComplexData.getAssociationConfig(key, associationDefinition);
            const items = this.getAssociatedValuesByKey({ lookupKey, isSync, extraData });
            this.saveJoinTable(foreignOptions, key);

            if ((items as any).then) {
                isPromise = true;
            }
            return this.processForeignRecords({
                items, ItemConstructor, conditions, foreignOptions, key, associationDefinition,
            }, fromBuilder);
        });
        if (isPromise) {
            return Bluebird.each(associatedValues, async associatedValue => associatedValue);
        }
        return associatedValues;
    }

    private processForeignRecords({
        items,
        ItemConstructor,
        conditions,
        foreignOptions,
        key,
        associationDefinition,
    }, fromBuilder: boolean = false) {
        const filteredRecords = this.getForeignRecords(items, foreignOptions)
            .filter(item => this.itemMatchesAllConditions(item, conditions));
        const isPromise = !!filteredRecords.then; // filteredRecords.some(record => record.then);
        if (isPromise) {
            return Bluebird.each(filteredRecords, async item => {
                const foreignRecordItem = await item;
                this.addNewAssociatedItem({
                    ItemConstructor,
                    item: foreignRecordItem,
                    isSingle: Boolean(associationDefinition.single),
                    associationName: key,
                }, fromBuilder);
            });
        }

        return filteredRecords.forEach(item => {
            this.addNewAssociatedItem({
                ItemConstructor,
                item,
                isSingle: Boolean(associationDefinition.single),
                associationName: key,
            }, fromBuilder);
        });
    }

    private getAssociatedValuesByKey({ lookupKey, isSync, extraData }): Record<string, any>[] {
        let items;
        if (isSync) {
            items = extraData[lookupKey] || [];
        } else {
            items = extraData[lookupKey] || (this.getAssociatedValue(lookupKey) as any).map(item => item.data.getDataValues()) || [];
        }
        return items;
    }

    protected hasFullDatasetLoaded(associationName: string) {
        return this.associatedValueLoadingOptions[associationName]?.[JSON.stringify({})];
    }

    protected hasAssociationLoaded(associationName: string, options: GetAssociatedOptions<T>): boolean {
        const optionsKey = ComplexData.generateLoadOptionKey(options);
        if (this.hasFullDatasetLoaded(associationName)) {
            return true;
        }
        if (this.alwaysRequestRemoteData() && (options.limit || options.skip || options.sorters)) {
            return false;
        }
        return Boolean(this.associatedValueLoadingOptions[associationName]?.[optionsKey]);
    }

    protected markAssociationLoaded(associationName: string, options: GetAssociatedOptions<T>): void {
        const optionsKey = ComplexData.generateLoadOptionKey(options);
        this.associatedValueLoadingOptions[associationName] = {
            ...(this.associatedValueLoadingOptions[associationName] || {}),
            [optionsKey]: true,
        };
    }

    private alwaysRequestRemoteData() {
        const configDefined = typeof process.env.ALWAYS_REQUEST_REMOTE_DATA !== 'undefined';
        return configDefined && process.env.ALWAYS_REQUEST_REMOTE_DATA;
    }

    private static generateLoadOptionKey(options) {
        return JSON.stringify(options, (key, value) => (typeof value === 'symbol' ? JSON.stringify(String(value)) : value));
    }

    static isOptionsEmpty<E extends Entity>(options: GetAssociatedOptions<E>): boolean {
        return !(options.limit || options.skip || options.sorters || options.filters);
    }

    isAssociationSingle(associationName: string): boolean {
        return Boolean(this.constructor.allowedAssociations[associationName].single);
    }

    protected getAssociatedValue(associationName: string, options: GetAssociatedOptions<T> = {}): Promise<any[]> | any[] {
        if (!this.canGetFromAssociatedValues(associationName, options)) {
            //
            // If the condition has any symbol value then it is a new item that is not yet saved.
            // Therefore, it is meaningless to try loading
            //
            if (this.isAnyConditionWithSymbolValue(associationName)) {
                return this.getAssociatedValuesFromCache(associationName);
            }
            //
            // If the association is single and we have that item loaded already into associatedValues
            // or in the cache, then we can just return that item.
            //
            if (this.isAssociationSingle(associationName)) {
                const itemInCache = this.getAssociatedValuesFromCache(associationName);
                if (itemInCache.length > 0) {
                    return itemInCache;
                }
            }
            return this.loadAssociatedValues(associationName, options);
        }
        return ComplexData.applyOptions<T>(this.associatedValues[associationName] || [], options);
    }

    private static applyOptions<E extends Entity>(values: any[], options: GetAssociatedOptions<E> = {}) {
        let filterFn: (any) => boolean = doNotFilter;
        if (values.length > 0 && options.filters) {
            filterFn = FilterBuilder.createFilterFn(options.filters) as (any) => boolean;
        }
        const filtered = values.filter(item => filterFn(item.data));
        if (filtered.length > 0 && options.sorters) {
            const sorterFn = FilterBuilder.createSorterFn(options.sorters) as (a: any, b: any) => number;
            filtered.sort((itemA, itemB) => sorterFn(itemA.data, itemB.data));
        }
        if (filtered.length > options.limit) {
            const start = options.skip || 0;
            const end = options.limit ? start + options.limit : filtered.length;
            return filtered.slice(start, end);
        }
        return filtered;
    }

    /**
     * Check if any used values in the condition is a Symbol
     *
     * @param {string} associationName
     * @returns {boolean}
     */
    protected isAnyConditionWithSymbolValue(associationName: string): boolean {
        return Object.values(this.generateConditions(associationName)).some(value => typeof value === 'symbol');
    }

    protected async getAssociatedValueCount(associationName: string, filters: Filter<T>[]): Promise<number> {
        const options = { limit: 1, skip: 0, filters };
        if (this.isListenerBinded(BaseData.EVENTS.MODEL_DATA_REQUEST) && !this.canGetFromAssociatedValues(associationName, options)) {
            if (this.isAnyConditionWithSymbolValue(associationName)) {
                return 0;
            }
            const associatedValuesKey = this.constructor.allowedAssociations[associationName].key;
            options.filters = this.extendFiltersWithConditions(associationName, options.filters);
            const associatedValues = await this.filterAssociatedData(associatedValuesKey, options);
            if (associatedValues?.responseData?.total) {
                return associatedValues?.responseData?.total;
            }
            return associatedValues && associatedValues.responseData ? associatedValues.responseData.length : 0;
        }
        return this.associatedValues[associationName]?.length || 0;
    }

    public getAssociatedValuesFromCache(associationName: string) {
        //
        // With staged loading we may already have it loaded actually
        //
        if (typeof this.associatedValues[associationName] !== 'undefined') {
            return this.associatedValues[associationName];
        }
        if (!this.cache) { // TODO PN-7728
            return [];
        }
        const associatedItemName = this.constructor.allowedAssociations[associationName].instance.getModelName();
        const condition = this.generateConditions(associationName);
        if (Object.prototype.hasOwnProperty.call(condition, 'id')) {
            const item = this.cache.get(associatedItemName, condition.id);
            if (item && this.itemMatchesAllConditions(item.data.dataValues, condition)) {
                return [item];
            }
            return [];
        }
        return this.cache.getCachedItemsByFilter(associatedItemName, (item => item && this.itemMatchesAllConditions(item.data.dataValues, condition)));
    }

    public getValueOfConditionWithSymbolId(associationName) {
        const condition = Object.entries(this.generateConditions(associationName)).find(([key, value]) => (key === 'id' && typeof value === 'symbol'));
        return condition ? condition[1] : null;
    }

    public generateConditions(associationName, ignoredAssociationConditionKeys = []): Partial<T> {
        const original = this.constructor.allowedAssociations[associationName].condition || {};
        return Object.entries(original)
            .filter(([associationKey]) => !ignoredAssociationConditionKeys.includes(associationKey))
            .reduce((copy, [key, value]) => {
                copy[key] = (typeof value === 'symbol') ? this.data[this.constructor.Entity.fieldSymbolValues[value]] : value;
                return copy;
            }, {});
    }

    /**
     * Determines if the association is already loaded and we can use the internal associatedValues array rather than
     * looking out to datawrapper.
     *
     * NOTE: currently lookup in associatedValues does not work because partially loaded datasets can not deal with
     * skip and limit options. E.g. loading associations in an empty associatedValues array by using a {skip: x} option
     * will not add the loaded values starting at index x to the associatedValues array, but it will add to the beginning.
     * Therefore subsequent calls with the same options will not be able to apply the skip/limit option properly.
     *
     * @param associationName
     * @param options
     * @private
     */
    private canGetFromAssociatedValues(associationName: string, options: GetAssociatedOptions<T>) {
        const associatedValuesKey = this.constructor.allowedAssociations[associationName].key;
        return this.hasAssociationLoaded(associatedValuesKey, options) && !options.skip && !options.limit;
    }

    /**
     * Load associated values via messages sent to the wrapper
     *
     * @param {string} associationName
     * @param {object} options - optional paging and filtering options
     * @returns {Promise<*>}
     */
    @memoizeRunningPromiseByKey((associationName, options) => `${associationName}_${ComplexData.generateLoadOptionKey(options)}`)
    @setMemoizeByInstance()
    private loadAssociatedValues(associationName: string, options: GetAssociatedOptions<T> = {}): Promise<any[]> | any[] {
        const associatedValuesKey = this.constructor.allowedAssociations[associationName].key;
        const filters = this.extendFiltersWithConditions(associationName, options.filters);
        const updatedOptions = { ...options, filters };
        if (this.isListenerBinded(BaseData.EVENTS.MODEL_DATA_REQUEST) && !this.canGetFromAssociatedValues(associationName, updatedOptions)) {
            return this.loadAssociatedData(associatedValuesKey, {
                ...updatedOptions,
                filters,
            })
                .then(async ({ responseData = [] } = {}) => {
                    if (!this.disposable) {
                        /**
                         * I have a feeling that we should not run into this on subsequent responses, as assications and items
                         * already created. This is now working, but if we have all the responseData in associatedValues items we should
                         * skip this.
                         */
                        await this.fillAssociatedValues(
                            { [associatedValuesKey]: responseData },
                            false,
                            false,
                            ([_key, association]) => (association.key === associatedValuesKey),
                        );
                        this.markAssociationLoaded(associatedValuesKey, updatedOptions);
                    }
                    /**
                     * What we want here is to change the plain responsData array to an array with complexData instances while managing the ordering of items.
                     */
                    const responseDataToProcess = responseData || [];
                    const modelClassName = this.constructor.allowedAssociations[associationName].instance.getModelName();
                    /** This is just disgusting, we have to force to generate complexInstances to be able to make fast lookups from cache
                     * Or we could iterate through associatedValues for every responseData element. This is just something which
                     * should be improved, but can't use associatedValueItems, as it is a map for sure, but not instances, only
                     * dataValues, and won't create complexData instances on access.
                     */
                    const associatedValues = this.associatedValues[associationName] || [];

                    if (this.cache && !this.disposable) {
                        associatedValues.forEach(dummy => dummy);
                        return responseDataToProcess.map(responseItem => this.cache.get(modelClassName, responseItem.id)).filter(Boolean);
                    }
                    if (this.disposable) {
                        return responseDataToProcess;
                    }
                    return responseData.map(responseItem => associatedValues.find(associatedValue => associatedValue.dataValues.id === responseItem.id)).filter(Boolean);
                });
        }

        return ComplexData.applyOptions(this.associatedValues[associationName] || [], updatedOptions);
    }

    protected extendFiltersWithConditions(associationName: string, filters: Filter<T>[] = []) {
        const conditions = this.generateConditions(associationName);
        const conditionFilters = this.conditionToFilter(conditions);
        return [...filters, ...conditionFilters];
    }

    private async getAssociatedValueById(associationName, id, options: GetAssociatedOptions<T> = {}) {
        const associatedValues = await this.getAssociatedValue(associationName, options);
        return associatedValues.find(item => item.data.id === id);
    }

    /**
     * In case of many to many relations cache the foreign key definition in a
     * associationName -> foreign key definition map
     *
     * @param {ForeignKeyDefinition} foreignOptions
     * @param {string} key
     */
    private saveJoinTable(foreignOptions: ForeignKeyDefinition, key: string) {
        if (typeof foreignOptions.model !== 'undefined') {
            this.joinTables[foreignOptions.model] = foreignOptions;
            this.associationToJoinTable[key] = [...(this.associationToJoinTable[key] || []), foreignOptions.model];
        }
    }

    /**
     * Check if foreign key relations are used
     *
     * @param items
     * @param foreignOptions
     * @returns {*}
     * @private
     */
    private getForeignRecords(items, foreignOptions: ForeignKeyDefinition) {
        //
        // Don't have foreign key definition? Just return the items as is
        //
        if (Object.keys(foreignOptions).length === 0 && foreignOptions.constructor === Object) {
            return items;
        }
        let isPromise = false;
        //
        // Got foreign key definitions... process them and convert the items to associated items
        //
        const foreignRecords = items.reduce((currentForeignRecords, item) => {
            const associatedItem = this.getItemByAssociation(item, foreignOptions);
            const returnValue = associatedItem ? [...currentForeignRecords, associatedItem] : currentForeignRecords;
            if ((returnValue as any).then) {
                isPromise = true;
            }
            return returnValue;
        }, []);

        if (isPromise) {
            return Bluebird.map(foreignRecords, async foreignRecord => foreignRecord);
        }

        return foreignRecords;
    }

    /**
     * Get an item by association. Using the passed in foreign key definition this
     * function checks whether the passed in item matches the required conditions
     *
     * @param {DataObject} associationItem
     * @param {ForeignKeyDefinition} foreignOptions
     * @returns {null}
     */
    private getItemByAssociation(associationItem: DataObject, foreignOptions: ForeignKeyDefinition) {
        const conditions = foreignOptions.condition || {};
        const meetsCondition = Object.entries(conditions)
            .every(([filterKey, value]) => (
                (typeof value === 'symbol' && associationItem[filterKey] === this.data[this.constructor.Entity.fieldSymbolValues[value]])
                || (typeof value !== 'symbol' && associationItem[filterKey] === value)));
        if (meetsCondition) {
            const associatedValue = this.getAssociatedValue(foreignOptions.associatedModel);
            if (typeof (associatedValue as any)?.then === 'function') {
                return (associatedValue as any)?.then(loadedAssociatedValue => this.findAssociatedItems(loadedAssociatedValue, foreignOptions, associationItem));
            }
            return this.findAssociatedItems(associatedValue, foreignOptions, associationItem);
        }
        return null;
    }

    private findAssociatedItems(associatedValue, foreignOptions: ForeignKeyDefinition, associationItem: DataObject) {
        return associatedValue.map(item => item.data.getDataValues())
            .find(item => Object.entries(foreignOptions.associatedField)
                .every(([key, value]) => item[value] === associationItem[key]));
    }

    public itemMatchesAllConditions(item, conditions = {}): boolean {
        return Reflect.ownKeys(conditions).map(key => [key, conditions[key]]).every(([filterKey, value]) => {
            if (filterKey === '$or' && Array.isArray(value)) {
                return value.some(orCondition => this.itemMatchesAllConditions(item, orCondition));
            }
            if (filterKey === 'company_id' && typeof item[filterKey] === 'undefined') {
                return true;
            }
            if (typeof value === 'symbol') {
                //
                // It can be symbol either because it is a field symbol or because it is a new item with symbol id
                //
                return (item[filterKey] === this.data[this.constructor.Entity.fieldSymbolValues[value]] || item[filterKey] === value);
            }
            if (typeof filterKey === 'symbol') {
                if (typeof this.constructor.Entity.fieldSymbolValues[filterKey] === 'undefined') {
                    throw Object.assign(new Error('Condition symbol key cannot be found in entity\'s fieldSymbolValus'), {
                        details: {
                            conditions,
                            filterKey,
                            item,
                        },
                    });
                }
                return this.data[this.constructor.Entity.fieldSymbolValues[filterKey]] === value;
            }
            return (item[filterKey] === value);
        });
    }

    private static getAssociationConfig(key, associationDefinition: AssociationDefinition<any, any>): SingleAssociationConfig {
        let lookupKey = associationDefinition.key;
        if (associationDefinition.via && associationDefinition.via.model) {
            lookupKey = associationDefinition.via.model;
        }
        return {
            lookupKey,
            ItemConstructor: associationDefinition.instance,
            conditions: associationDefinition.condition || {},
            isSingle: (associationDefinition as AssociationDefinitionSingle<any, any>).single !== undefined ? (associationDefinition as AssociationDefinitionSingle<any, any>).single : false,
            foreignOptions: associationDefinition.via || {},
            methodName: this.getMethodName(key),
        };
    }

    public static getMethodName(key: string): string {
        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    /**
     * Process available associations and construct add/remove, etc
     * methods for them
     *
     * @private
     */
    private processAssociations(): void {
        if (!Object.getOwnPropertyDescriptor(this.constructor, 'associationsProcessed')?.value) {
            memoizeGetter(this.constructor, 'allowedAssociations');
            memoizeGetter(this.constructor, 'modelDefinition');
            Object.entries(this.constructor.allowedAssociations)
                .forEach(([key, associationDefinition]) => {
                    this.constructor.getAssociationMethodDefinitions()
                        .forEach(methodDefinition => {
                            this.createMethod(key, associationDefinition, methodDefinition);
                        });
                });
        }
        this.constructor.associationsProcessed = true;
    }

    /**
     * Create get/set/add/etc methods based on the association config
     *
     * @param {string} associationName
     * @param {AssociationDefinition} associationDefinition
     * @param {MethodDefinition} methodDefinition
     */
    private createMethod(associationName: string, associationDefinition: AssociationDefinition<any, any>, methodDefinition: MethodDefinition): void {
        const {
            methodName,
            ItemConstructor,
            isSingle,
        } = this.constructor.getAssociationConfig(associationName, associationDefinition);
        //
        // All methods except multiple in case of single :-)
        //
        if ((methodDefinition.isSingle === isSingle)) {
            const actualMethodName = methodDefinition.prefix + methodName + (methodDefinition.postfix || '');

            if (typeof this.constructor.prototype[actualMethodName] !== 'undefined') {
                throw new Error(`Method already exists: ${this.constructor.getModelName()}, ${actualMethodName}`);
            }
            //
            // If the association's ComplexData instance is defined as ComplexData class
            // then we do not want to instantiate these so the related methods should not
            // be created unless the method definition tells we do not need instance
            //
            if (!ItemConstructor && !methodDefinition.noInstanceNeeded) {
                return;
            }

            this.constructor.prototype[actualMethodName] = function (...args) {
                const result = methodDefinition.handler.apply(this, [{
                    ItemConstructor,
                    isSingle,
                    associationName,
                }, ...args]);

                let pendingResult = result;

                if (['add', 'set', 'remove', 'unset'].includes(methodDefinition.prefix)) {
                    if (result.then) {
                        // eslint-disable-next-line no-async-promise-executor
                        pendingResult = new Promise(async resolve => {
                            await result;
                            this.noticeAssociationChange(associationName);
                            this.emit(ComplexData.EVENTS.OWN_ASSOCIATION_ADDED, { associationName, item: this });
                            resolve(result);
                        });
                    } else {
                        this.noticeAssociationChange(associationName);
                        this.emit(ComplexData.EVENTS.OWN_ASSOCIATION_ADDED, { associationName, item: this });
                    }
                }
                return pendingResult;
            };
        }
    }

    private noticeAssociationChange(associationName: string) {
        this.emit(ComplexData.EVENTS.ASSOCIATION_CHANGED, {
            association: this.constructor.allowedAssociations[associationName],
            item: this,
        });
    }

    /**
     * Abstract function to return associations available.
     * To be overridden by inherited classes
     *
     * @returns {{}}
     */
    public static get allowedAssociations(): AssociationConfig<any, any> {
        return {};
    }

    protected get fieldChangeEventHandlers(): FieldChangeEventHandlers {
        return {};
    }

    public getInitialId(): number | symbol {
        return this.initialId;
    }

    private createProxy(associationName: string, extraData: DataObject = {}) {
        const proxyTarget = this.getGeneratedAssociatedValueItems(associationName);
        return new Proxy(proxyTarget, {
            get: (target, key) => {
                if (PROXY_NON_ENUMERABLE_KEYS.has(key)) {
                    return target[key];
                }
                const currentItem = proxyTarget[key];
                if (!currentItem) {
                    return target[key];
                }
                return this.cacheItemInstance(currentItem, () => this.generateExtraDataForNewItem(extraData));
            },
            set: (target, key, value, ...args) => {
                let newValue = value;
                if (value && hasMixin(value, ComplexData)) {
                    newValue = this.proxyMap[value.proxyMapKey];
                }
                return Reflect.set(target, key, newValue, ...args);
            },
        });
    }

    private generateExtraDataForNewItem(extraData) {
        return Object.keys(extraData).length > 0 ? extraData : {
            ...Object.entries(this.associatedValueItems).reduce((currentExtraData, [key, value]) => {
                currentExtraData[key] = value[valuesAsArrayKey];
                return currentExtraData;
            }, {}),
        };
    }

    private cacheItemInstance(currentItem: DataObject, extraDataGenerator: () => DataObject): this {
        if (!currentItem[instanceSymbol]) {
            currentItem[instanceSymbol] = this.createItemInstance(currentItem, extraDataGenerator());
            currentItem[itemIsInstantiatedSymbol] = true;
        }
        return currentItem[instanceSymbol];
    }

    protected createItemInstance(item: DataObject, extraData: DataObject) {
        const { modelName } = item[constructorSymbol].prototype.constructor.modelDefinition;
        let instance = this.getInstanceFromCache(item, modelName);
        if (!instance) {
            instance = new item[constructorSymbol](item, extraData, {
                fromBuilder: true,
                parent: this,
                cache: this.cache,
            });
            instance.proxyMapKey = Symbol('proxyMapKey');
            this.proxyMap[instance.proxyMapKey] = item;
            const isNewInstance = instance.isNewItem() && !item[isNewSymbol];
            instance.initAssociatedValues(isNewInstance);
        } else {
            if (!instance.proxyMapKey) {
                instance.proxyMapKey = Symbol('proxyMapKey');
                this.proxyMap[instance.proxyMapKey] = item;
            }
            // @ts-ignore
            this.setupEventForwarding(instance);
        }
        return instance;
    }

    private addEventHandlers() {
        this.on(ComplexData.EVENTS.ID_UPDATED, (item: ComplexData<T>) => {
            let gotAssociation = false;
            Object.entries(this.getAssociationsForSameConstructor(item.constructor))
                .forEach(([associationName]) => {
                    if (this.associatedValueItems[associationName]) {
                        this.updateIdKeyInAssociatedValueItems(item.initialId, associationName, item.getDataWithState());
                        gotAssociation = true;
                    }
                });
            if (gotAssociation && typeof item.initialId === 'symbol') {
                this.emit(ComplexData.EVENTS.OWN_ASSOCIATION_SAVED, { item });
            }
        });
        //
        // In case of Delete event lets check if this item is in any associations
        //
        this.on(ComplexData.EVENTS.ITEM_DELETED, itemToDelete => {
            Object.entries(this.getAssociationsForSameConstructor(itemToDelete.constructor))
                .forEach(([associationName, association]) => {
                    this.removeAssociationLinks(association, itemToDelete);
                    const deleteIndex = this.associatedValues[associationName] && this.associatedValues[associationName].indexOf(itemToDelete);
                    if (this.deletedAssociatedItems.indexOf(itemToDelete) < 0 && !itemToDelete.isNewItem() && this.getAssociatedItemSet(true).has(itemToDelete)) {
                        this.deletedAssociatedItems.push(itemToDelete);
                    }
                    if (deleteIndex >= 0) {
                        this.associatedValues[associationName].splice(deleteIndex, 1);
                        this.getGeneratedAssociatedValueItems(associationName);
                        this.noticeAssociationChange(associationName);
                    }
                });
        });

        this.on(ComplexData.EVENTS.NEW_ITEM_ASSOCIATED, ({ itemCopy, associationKey, extraData }) => {
            Object.entries(this.constructor.allowedAssociations)
                .filter(([_key, association]) => (association.key === associationKey))
                .forEach(([associationName, association]) => {
                    if (association.instance === this.constructor && itemCopy.id === this.data.id) {
                        return;
                    }

                    if (this.itemMatchesAllConditions(itemCopy, association.condition)) {
                        this.saveItemAndUpdateProxy(associationName, association, itemCopy, extraData);
                    }
                });
        });
    }

    protected entityChanged(key, newValue, oldValue) {
        if (this.conditionalKeys.has(key)) {
            this.clearOutdatedAssociations(key);
        }

        Object.entries(this.fieldChangeEventHandlers).forEach(([fieldName, handler]) => {
            if (key === fieldName) {
                handler(newValue, oldValue);
            }
        });
    }

    public updateIdKeyInAssociatedValueItems(oldId, associationName: string, data: DataObject): void {
        const association = this.associatedValueItems[associationName];
        if (association && association[oldId]) {
            const associatedValue = Object.assign(association[oldId], data);
            this.associatedValueItems[associationName][oldId] = undefined;
            associatedValue.id = data.id;
            this.associatedValueItems[associationName][data.id] = associatedValue;
            this.getGeneratedAssociatedValueItems(associationName);
        }
    }

    private clearOutdatedAssociations(fieldName: string): void {
        const associations: AssociationConfig<any, any> = this.collectAssociationsForFieldName(fieldName);
        Object.entries(associations)
            .filter(([associationName]) => this.associatedValues[associationName])
            .forEach(([associationName, association]) => {
                this.clearOutdatedItemsFromAssociation(associationName, association);
            });
    }

    private clearOutdatedItemsFromAssociation(associationName: string, association: AssociationDefinition<any, any>): void {
        let cleaned = false;
        this.associatedValues[associationName].forEach(associatedItem => {
            if (!this.itemMatchesAllConditions(associatedItem, association.condition || {})) {
                cleaned = true;
                this.removeItemFromAssociation(associationName, associatedItem.data.id);
                this.removeFromEventForwardingIfNeeded(associatedItem);
            }
        });

        if (cleaned) {
            this.getGeneratedAssociatedValueItems(associationName);
            this.resetAssociationLoading(associationName, association.key);
        }
    }

    private removeFromEventForwardingIfNeeded(associatedItem): void {
        if (!this.isAssociatedTo(associatedItem)) {
            this.removeFromEventForwarding(associatedItem);
        }
    }

    private resetAssociationLoading(associationName: string, associationKey: string): void {
        this.associatedValues[associationName] = undefined;
        this.resetAssociatedValueItems(associationName);
        this.loadedAssociationValuesKeys[associationKey] = undefined;
        this.associatedValueLoadingOptions[associationKey] = undefined;
    }

    private resetAssociatedValueItems(associationName) {
        this.associatedValueItems[associationName] = undefined;
        this.getGeneratedAssociatedValueItems(associationName);
    }

    private collectAssociationsForFieldName(fieldName: string): AssociationConfig<any, any> {
        return Object.entries(this.constructor.allowedAssociations)
            .reduce((associations, [associationName, association]) => {
                const { condition = {} } = association;
                if (this.isFieldPresentInCondition(condition, fieldName)) {
                    associations[associationName] = association;
                }
                return associations;
            }, {});
    }

    private isFieldPresentInCondition(condition: FieldMap<T>, fieldName: string): boolean {
        return Object.values(condition).some((conditionKey: symbol | string) => this.constructor.Entity.fieldSymbolValues[conditionKey] === fieldName);
    }

    private removeAssociationLinks(association: AssociationDefinition<any, any>, itemToDelete: ComplexData<T>): void {
        Object.entries(association.condition || {})
            .forEach(([conditionKey, conditionValue]) => {
                if (typeof conditionValue === 'symbol' && this.data[this.constructor.Entity.fieldSymbolValues[<symbol>conditionValue]] === itemToDelete.data[conditionKey]) {
                    //
                    // If the parent is also deleted then we don't need to unset the linking
                    //
                    if (!this.isDeleted && (association as AssociationDefinitionSingle<any, any>).single) {
                        if (Object.values(this.constructor.Entity.getForeignFieldSymbols()).includes(<symbol>conditionValue)) {
                            // @ts-ignore
                            this.data[this.constructor.Entity.fieldSymbolValues[<symbol>conditionValue]] = undefined;
                        }
                    }
                }
            });
    }

    private addAssociationToCache(associationName: string): void {
        if (this.cache) {
            this.cache.updateAssociationMap(
                this,
                this.constructor.allowedAssociations[associationName],
                associationName,
            );
        }
    }

    private addToCache(item: ComplexData<T>): void {
        if (this.cache) {
            this.cache.add(item);
        }
    }

    private removeFromCache(): void {
        if (this.cache) {
            this.cache.remove(this);
        }
    }

    private updateIdInCache(item: ComplexData<T>): void {
        if (this.cache) {
            this.cache.updateId(item);
        }
    }

    public update(sourceItem: ComplexData<T>): void {
        this.data.suppressEvents();
        Object.assign(this.data, sourceItem.data);
        this.data.unSuppressEvents();
    }

    /**
     * Create a new associated item with the given parameters. Any addition to the associatedValues object
     * must be done via this function.
     *
     * @param {string} associationName
     * @param {Function} ItemConstructor
     * @param {DataObject | ComplexData} item
     * @param {DataObject} extraData
     * @param {boolean} isSingle
     * @param {boolean} fromBuilder
     * @returns {number}
     */
    public addNewAssociatedItem(
        {
            associationName,
            ItemConstructor,
            item,
            extraData = this.extraData,
            isSingle,
        }: {
            associationName: string,
            ItemConstructor: typeof ComplexData,
            item: DataObject | ComplexData<T>,
            extraData?: DataObject,
            isSingle: boolean,
        },
        fromBuilder: boolean = false,
    ): number {
        const association = this.constructor.allowedAssociations[associationName];
        if (isSingle || (association as AssociationDefinitionSingle<any, any>).single !== undefined ? (association as AssociationDefinitionSingle<any, any>).single : false) {
            this.removePreviousItemFromSingleAssociation(associationName, (<DataObject>item).id || ((<ComplexData<T>>item).data && (<ComplexData<T>>item).data.id));
        }

        this.extendExtraDataWithSelf(extraData);

        let itemCopy = item[itemIsInstantiatedSymbol] ? item : { ...item };
        itemCopy[constructorSymbol] = ItemConstructor;

        BaseData.createIdSymbol(itemCopy);
        this.buildAssociations(associationName, itemCopy, extraData);

        if (itemCopy[itemIsInstantiatedSymbol]) {
            this.setupEventForwarding(itemCopy[instanceSymbol]);
            this.addToCache(itemCopy[instanceSymbol]);
            if (!itemCopy[instanceSymbol].proxyMapKey) {
                itemCopy[instanceSymbol].proxyMapKey = Symbol('proxyMapKey');
            }
            this.proxyMap[itemCopy[instanceSymbol].proxyMapKey] = item;
        }

        if (this.cache && (this.cache.constructor as any).isCacheable(association)) {
            this.cache.reverseAssociations.add(this, ItemConstructor.getModelName(), itemCopy.id);
        }

        let returnId = itemCopy.id;
        if (item[isUpdatedSymbol] || item[isDeletedSymbol]) {
            //
            // Make sure the item is instantiated
            //
            itemCopy = this.cacheItemInstance(itemCopy, () => extraData);
            if (item[isUpdatedSymbol]) {
                itemCopy.data.isChanged = true;
            }
            if (item[isDeletedSymbol]) {
                this.deletedItemsHolderForClone.push(itemCopy);
            }
            returnId = itemCopy.data.id;
        }

        if (typeof itemCopy.id === 'symbol') {
            this.emit(ComplexData.EVENTS.NEW_ITEM_ADDED, itemCopy);
        }

        if (fromBuilder) {
            this.loadedAssociationValuesKeys[associationName] = true;
        }

        return returnId;
    }

    private removePreviousItemFromSingleAssociation(associationName: string, newItemId: number): void {
        if (!this.associatedValueItems[associationName]) {
            return;
        }
        const associatedItems = getValuesIncludingSymbolProperties(this.associatedValueItems[associationName], [valuesAsArrayKey]);
        const previousItem = associatedItems[0] && associatedItems[0][instanceSymbol];
        if (previousItem && previousItem.data.id !== newItemId) {
            this.unlinkAssociatedItem(associationName, previousItem);
            const reverseAssociations = previousItem.getAssociationsForSameConstructor(this.constructor);
            Object.entries(reverseAssociations).forEach(([reverseAssociationName]) => {
                if (this.isAssociatedTo(previousItem)) {
                    previousItem.unlinkAssociatedItem(reverseAssociationName, this);
                }
            });
        }
    }

    private isAssociatedTo(instance: ComplexData<T>) {
        return Object.entries(instance.constructor.allowedAssociations)
            .filter(([, association]) => association.instance === this.constructor)
            .some(([key]) => instance.associatedValueItems[key] && instance.associatedValueItems[key][this.data.id]);
    }

    private buildAssociations(associationName: string, itemCopy: DataObject | ComplexData<T> | {}, extraData: DataObject) {
        this.addToAllPossibleAssociations(associationName, itemCopy, extraData);

        this.createJoinTableItems(associationName, itemCopy);
    }

    private extendExtraDataWithSelf(extraData: DataObject): void {
        extraData[(<any> this.constructor).associationKey] = extraData[(<any> this.constructor).associationKey] || [];
        const data = this.data.getDataValues();
        if (!extraData[this.constructor.associationKey].some(item => item.id === data.id)) {
            extraData[(<any> this.constructor).associationKey].push(data);
        }
    }

    private addToAllPossibleAssociations(mainAssociationName: string, itemCopy: DataObject | ComplexData<T> | {}, extraData: DataObject): void {
        let savedAtLeastOnce: boolean = false;
        this.getAllPossibleAssociations(mainAssociationName, itemCopy)
            .forEach(([associationName, association]) => {
                const savedToAssociation: boolean = this.saveItemAndUpdateProxy(associationName, association, itemCopy, extraData);
                savedAtLeastOnce = savedAtLeastOnce || savedToAssociation;
            });

        if (savedAtLeastOnce) {
            this.emit(ComplexData.EVENTS.NEW_ITEM_ASSOCIATED, {
                itemCopy,
                extraData,
                associationKey: this.constructor.allowedAssociations[mainAssociationName].key,
            });
        }
    }

    private saveItemAndUpdateProxy(associationName: string, association: AssociationDefinition<any, any>, itemCopy: DataObject | ComplexData<T> | {}, extraData: DataObject): boolean {
        const saved = this.saveItemToAssociatedValueItems(
            associationName,
            (association as AssociationDefinitionSingle<any, any>).single !== undefined ? (association as AssociationDefinitionSingle<any, any>).single : false,
            itemCopy,
        );

        if (saved) {
            this.associatedValues[associationName] = this.associatedValues[associationName] || this.createProxy(associationName, extraData);
            this.validateAndSetDefaults(
                associationName,
                itemCopy[itemIsInstantiatedSymbol] ? itemCopy[instanceSymbol].data : itemCopy,
                (association as AssociationDefinitionSingle<any, any>).single !== undefined ? (association as AssociationDefinitionSingle<any, any>).single : false,
            );
        }
        return saved;
    }

    private getAllPossibleAssociations(mainAssociationName: string, itemCopy: DataObject | ComplexData<T> | {}): [string, AssociationDefinition<any, any>][] {
        return this.getAssociationsForSameModel(mainAssociationName)
            .filter(([associationName, association]: [string, AssociationDefinition<any, any>]) => associationName === mainAssociationName || this.itemMatchesAllConditions(itemCopy, association.condition || {}));
    }

    private getAssociationsForSameModel(associationName: string): [string, AssociationDefinition<any, any>][] {
        return Object.entries(this.constructor.allowedAssociations)
            .filter(([_key, value]) => value.instance === this.constructor.allowedAssociations[associationName].instance);
    }

    private getAssociationsForSameConstructor(constructor: Function): AssociationConfig<any, any> {
        return Object.entries(this.constructor.allowedAssociations)
            .reduce((associations, [key, value]) => {
                if (value.instance === constructor) {
                    associations[key] = value;
                }
                return associations;
            }, {});
    }

    private saveItemToAssociatedValueItems(associationName: string, isSingle: boolean, itemCopy: DataObject): boolean {
        this.associatedValueItems[associationName] = this.getGeneratedAssociatedValueItemsObject(associationName);

        if (this.associatedValueItems[associationName][itemCopy.id]) {
            return false;
        }
        if (isSingle) {
            const key = Reflect.ownKeys(this.associatedValueItems[associationName]).filter(filterKey => filterKey !== valuesAsArrayKey);
            delete this.associatedValueItems[associationName][key[0]];
        }

        this.associatedValueItems[associationName][itemCopy.id] = itemCopy;
        this.getGeneratedAssociatedValueItems(associationName);

        return true;
    }

    private getGeneratedAssociatedValueItemsObject(associationName: string) {
        if (!this.associatedValueItems[associationName]) {
            this.addAssociationToCache(associationName);
            this.associatedValueItems[associationName] = { [valuesAsArrayKey]: [] };
        }
        return this.associatedValueItems[associationName];
    }

    public async delete() {
        /**
         * We still have associations without complex data implementations, which is problematic
         * with some recent change, as we would try to instantiate a non-existing complex data model before
         * deletion.
         */

        if (!this.isNewItem() && this.forwardedEventsRoot[ComplexData.EVENTS.ITEM_DELETED] && this.forwardedEventsRoot[ComplexData.EVENTS.ITEM_DELETED].getAssociatedItemSet(true).has(this)) {
            this.forwardedEventsRoot[ComplexData.EVENTS.ITEM_DELETED].deletedAssociatedItems.push(this);
        }

        const cascadeDeleteAssociations = Object.entries(this.constructor.allowedAssociations)
            .filter(([_associationKey, associationValue]) => associationValue.cascadeDelete && associationValue.instance);

        await Promise.all(cascadeDeleteAssociations.map(async ([associationName]) => {
            const associatedValue = await this.getAssociatedValue(associationName);
            if (associatedValue) {
                return Bluebird.map(associatedValue, async associatedItem => {
                    if (this.deletedAssociatedItems.indexOf(associatedItem) < 0 && !associatedItem.isNewItem()) {
                        this.deletedAssociatedItems.push(associatedItem);
                    }
                    return associatedItem.delete();
                });
            }
            this.getGeneratedAssociatedValueItems(associationName);
            return null;
        }));
        this.privateDeleted = true;
        this.emit(ComplexData.EVENTS.ITEM_DELETED, this);

        this.removeFromCache();

        return null;
    }

    private async removeAssociatedItem(options): Promise<void> {
        const { associationName, item, isSingle } = options;
        let associatedItem = item && item[instanceSymbol];

        if (!associatedItem) {
            associatedItem = isSingle ? (await this.getAssociatedValue(associationName))[0] : await this.getAssociatedValueById(associationName, item.id);
        }

        if (this.constructor.allowedAssociations[associationName].cascadeDelete) {
            await associatedItem.delete();
        }
        this.unlinkAssociatedItem(associationName, associatedItem);
    }

    private unlinkAssociatedItem(associationName: string, associatedItem: ComplexData<T>): void {
        associatedItem.reverseAssociations.delete(this);

        this.removeAssociationLinks(this.constructor.allowedAssociations[associationName], associatedItem);

        const associatedItemId = associatedItem.getData().id;
        this.removeFromAllAssociations(associatedItem, associatedItemId);

        this.getGeneratedAssociatedValueItems(associationName);
        this.removeFromEventForwardingIfNeeded(associatedItem);
    }

    private removeFromAllAssociations(associatedItem: ComplexData<T>, associatedItemId: any): void {
        Object.entries(this.constructor.allowedAssociations)
            .filter(([, association]) => association.instance === associatedItem.constructor)
            .forEach(([associationName]) => {
                this.removeItemFromAssociation(associationName, associatedItemId);
            });
    }

    /**
     * Remove ourselves from all associations of the passed in instance
     *
     * @param {ComplexData} associatedItem
     */
    public removeFromReverseAssociations(associatedItem: ComplexData<T>) {
        Object.keys(associatedItem.getAssociationsForSameConstructor(this.constructor))
            .filter(associationName => Boolean(associatedItem.associatedValueItems[associationName] && associatedItem.associatedValueItems[associationName][this.data.id]))
            .forEach(associationName => {
                associatedItem.unlinkAssociatedItem(associationName, this);
                associatedItem.noticeAssociationChange(associationName);
            });
    }

    public removeDeletedAssociation(associatedItemConstructor: typeof ComplexData, id: number) {
        const deletedItemIndex = this.deletedAssociatedItems.findIndex(deletedItem => deletedItem.constructor === associatedItemConstructor
            && deletedItem.data.id === id);
        if (deletedItemIndex !== -1) {
            this.deletedAssociatedItems.splice(deletedItemIndex, 1);
        }
    }

    private removeItemFromAssociation(associationName: any, associatedItemId: any) {
        if (this.associatedValueItems[associationName]) {
            delete this.associatedValueItems[associationName][associatedItemId];
            _.remove(this.associatedValueItems[associationName][valuesAsArrayKey], { id: associatedItemId });
        }
    }

    private validateAndSetDefaults(associationName: string, item: DataObject, isSingle: boolean): void {
        const association = this.constructor.allowedAssociations[associationName]
            || this.joinTables[associationName];

        if (typeof association !== 'undefined') {
            Reflect.ownKeys(association.condition || {}).map(key => [key, association.condition[key]]).forEach(([key, value]) => {
                if (isSingle) {
                    if (typeof value === 'symbol') {
                        if (Object.values(this.constructor.Entity.getForeignFieldSymbols()).includes(value)) {
                            this.data[this.constructor.Entity.fieldSymbolValues[value]] = item[key];
                        } else {
                            item[key] = this.data[this.constructor.Entity.fieldSymbolValues[value]];
                        }
                    }
                    if (typeof key === 'symbol') {
                        if (Object.values(this.constructor.Entity.getFieldSymbols()).includes(key)) {
                            // @ts-ignore
                            this.data[this.constructor.Entity.fieldSymbolValues[key]] = value;
                        } else {
                            throw Object.assign(new Error('Condition symbol key cannot be found in entity\'s fieldSymbolValus'), {
                                details: {
                                    conditions: association.condition,
                                    key,
                                    item,
                                },
                            });
                        }
                    }
                }
                item[key] = this.validateAgainstAssociationCondition(item, key, value);
            });
        }
    }

    private validateAgainstAssociationCondition(item: DataObject, key: string, value) {
        const newValue = this.getValueForCondition(value);
        if (key !== '$or'
            && typeof item[key] !== 'undefined'
            && item[key] !== newValue) {
            throw new exceptions.ValidationError({
                // String constructor is needed for Symbols
                message: `${String(key)} value is invalid as it is not matching the criteria defined in the allowedAssociations config. Value: ${String(item[key])}, Expected: ${String(newValue)}`,
            });
        }
        return newValue;
    }

    private createJoinTableItems(associationName: string, item: DataObject) {
        if (Array.isArray(this.associationToJoinTable[associationName])) {
            this.associationToJoinTable[associationName].forEach(joinTableName => {
                const association = this.joinTables[joinTableName];
                const joinItem = this.createJoinTableItem(association, item);
                this.addNewJoinTableItem(association, joinItem);
            });
        }
    }

    private getValueForCondition(value) {
        return (typeof value === 'symbol') ? this.data[this.constructor.Entity.fieldSymbolValues[value]] : value;
    }

    private createJoinTableItem(association: ForeignKeyDefinition, item: DataObject): DataObject {
        const joinItem = {};
        Object.entries(association.associatedField).forEach(([key, value]) => {
            this.validateAgainstAssociationCondition(item, key, value);
            joinItem[key] = item[value];
        });
        Object.entries(association.condition).forEach(([key, value]) => {
            joinItem[key] = this.validateAgainstAssociationCondition(item, key, value);
        });
        return joinItem;
    }

    private addNewJoinTableItem(foreignOptions: ForeignKeyDefinition, associationItem: DataObject): void {
        this.addNewAssociatedItem({
            associationName: foreignOptions.model,
            ItemConstructor: ComplexData,
            item: associationItem,
            isSingle: false,
        });
    }

    public async validate(params?: any): Promise<ValidationResult> {
        if (this.isDeleted) {
            return new ValidationResult();
        }
        //
        // Do validation of this object
        //
        const validationResult = await this.validateItem(params);
        //
        // Do validation of all non-deleted in-memory associated objects
        //
        const associatedItems = this.getChangedItems();
        await Promise.all([...associatedItems].map(async associatedItem => {
            if (!associatedItem.isDeleted && !this.sameAsAssociatedItem(associatedItem)) {
                const itemValidationResult = await associatedItem.validateItem(params);
                validationResult.mergeWith(itemValidationResult);
            }
            return null;
        }));

        return validationResult;
    }

    private sameAsAssociatedItem(associatedItem) {
        return this === associatedItem;
    }

    private async validateItem(params: any): Promise<ValidationResult> {
        const validationResult = new ValidationResult();
        const extra = (params && params.extra) || {};
        validationResult.mergeWith(await ModelValidator.validate(this, (<typeof ComplexData> this.constructor).modelDefinition, extra));

        const customRuleValidationResult = await this.validateCustomRules(params);
        validationResult.mergeWith(customRuleValidationResult);

        return validationResult;
    }

    protected async validateCustomRules(params: any): Promise<ValidationResult> {
        return new ValidationResult();
    }

    /**
     * Save the item and any associations by using the resolver
     * passed in
     *
     * @param resolver
     */
    public save(resolver) {
        if (this.disposable) {
            throw new DisposableComplexDataError('Cannot save disposable instances', this);
        }
        const saveResult = (this.constructor as any).saveAll([this], resolver);
        this.deletedAssociatedItems = [];
        return saveResult;
    }

    public static saveAll(complexDataObjects: ComplexData<any>[], resolver) {
        const items = complexDataObjects.reduce((collection, complexDataObject) => collection.concat(complexDataObject.getChangedItems()), []);
        return this.saveItems(items, resolver);
    }

    private static async saveItems(items: ComplexData<any>[], resolver) {
        const itemsToSave = items.filter(item => {
            if (item.saving) {
                return false;
            }
            item.saving = true;
            item.initialId = item.data.id;
            item.data.mute();
            return true;
        });
        try {
            await resolver.resolve({ items: itemsToSave, rootNode: 'dataValues' });
            itemsToSave.forEach(item => {
                item.data.isChanged = false;
                if (item.data.id !== item.initialId) {
                    item.updateIdInCache(item);

                    [...item.reverseAssociations]
                        .forEach(associatedItem => associatedItem.emit(ComplexData.EVENTS.ID_UPDATED, item));

                    item.emit(ComplexData.EVENTS.FIELD_CHANGED, {
                        key: 'id',
                        newValue: item.data.id,
                        oldValue: item.initialId,
                        item,
                    });
                }
            });
        } finally {
            itemsToSave.forEach(item => {
                item.saving = false;
                item.data.unmute();
            });
        }
    }

    private getChangedItems(): ComplexData<T>[] {
        const collectedSet = new Set<ComplexData<T>>();

        [...this.getDeepAssociatedItems(), this].forEach(item => {
            collectedSet.add(item);
        });

        return [...collectedSet]
            .filter(item => (item && hasMixin(item, ComplexData)) && (item.isUpdated || item.isNew || item.isDeleted));
    }

    private getDeepAssociatedItems(includeNonInstantiated = false): Set<ComplexData<T>> {
        const collected = new Set<ComplexData<T>>();
        const processed = new Set<ComplexData<T>>();

        this.collectDeepAssociatedItems({ collected, processed, includeNonInstantiated });

        return collected;
    }

    private collectDeepAssociatedItems({
        collected,
        processed,
        includeNonInstantiated,
    }: {
        collected: Set<ComplexData<T>>,
        processed: Set<ComplexData<T>>,
        includeNonInstantiated: boolean
    }) {
        processed.add(this);

        const associatedItems = [...this.getAssociatedItemSet(includeNonInstantiated), ...this.deletedAssociatedItems];
        associatedItems.forEach(item => {
            collected.add(item);

            (item.deletedAssociatedItems || []).forEach(deletedAssociatedItem => {
                collected.add(deletedAssociatedItem);
            });

            if (!processed.has(item) && item.collectDeepAssociatedItems) {
                item.collectDeepAssociatedItems({ collected, processed, includeNonInstantiated });
            }
        });
    }

    /**
     * Get the associated items as a new Set
     *
     * @returns {Set<*>}
     */
    public getAssociatedItemSet(includeNonInstantiated): Set<ComplexData<T>> {
        return Object.entries(this.associatedValueItems).reduce((accumulatedItems, [_key, items]) => new Set([
            ...accumulatedItems,
            ...items[valuesAsArrayKey]
                .reduce((instances, item) => {
                    if (item[instanceSymbol]) {
                        instances.push(item[instanceSymbol]);
                    } else if (includeNonInstantiated) {
                        instances.push(item);
                    }
                    return instances;
                }, []),
        ]), new Set<ComplexData<T>>());
    }

    /**
     * Clones the current object
     *
     * @returns {ComplexData}
     */

    public async clone(instantiatedClones: AssociationNameToComplexDataArray = {}, parent: ComplexData<T> = null): Promise<this> {
        const clonedObject = await this.createClonedObject(parent);
        this.rememberClonedInstance(clonedObject, instantiatedClones);

        await this.cloneAssociations(instantiatedClones, clonedObject);
        await this.cloneDeletedAssociations(instantiatedClones, clonedObject);

        this.updateClonedAssociations(clonedObject);
        this.cloneLoadingOptions(clonedObject);

        return clonedObject;
    }

    private cloneLoadingOptions(clonedObject: ComplexData<T>) {
        clonedObject.associatedValueLoadingOptions = _.cloneDeep(this.associatedValueLoadingOptions);
    }

    /**
     * Update associatedValues by creating new proxies for it.
     *
     * @param {ComplexData} clonedObject
     */
    private updateClonedAssociations(clonedObject: ComplexData<T>) {
        Object.keys(this.constructor.allowedAssociations)
            .filter(associationName => this.associatedValues[associationName])
            .forEach(associationName => {
                clonedObject.associatedValues[associationName] = clonedObject.createProxy(associationName);
            });
    }

    /**
     * Clone all deleted associations for the clonedObject
     *
     * @param {{}} instantiatedClones
     * @param {ComplexData} clonedObject
     * @returns {Promise<void>}
     */
    private async cloneDeletedAssociations(instantiatedClones: AssociationNameToComplexDataArray, clonedObject: ComplexData<T>): Promise<void> {
        await Bluebird.each(this.deletedAssociatedItems, async item => {
            const { id } = item.data;
            const key = (<any>item.constructor).associationKey;
            instantiatedClones[key] = instantiatedClones[key] || {};
            let clonedInstance = instantiatedClones[key][id];
            if (!clonedInstance) {
                clonedInstance = await item.clone(instantiatedClones, clonedObject);
            }

            if (!clonedObject.deletedAssociatedItems.includes(clonedInstance)) {
                clonedObject.deletedAssociatedItems.push(clonedInstance);
            }
        });
    }

    /**
     * Clone all associations for the clonedObject
     *
     * @param {{}} instantiatedClones
     * @param {ComplexData} clonedObject
     * @returns {Promise<void>}
     */
    private async cloneAssociations(instantiatedClones: AssociationNameToComplexDataArray, clonedObject: ComplexData<T>): Promise<void> {
        await Bluebird.each(Object.entries(this.associatedValueItems), async ([associationName, items]) => {
            const associationKey = this.constructor.allowedAssociations[associationName].key;

            instantiatedClones[associationKey] = instantiatedClones[associationKey] || {};
            clonedObject.associatedValueItems[associationName] = clonedObject.getGeneratedAssociatedValueItemsObject(associationName);

            await Bluebird.each(entriesWithSymbols(items, [valuesAsArrayKey]), async ([id, item]) => {
                if (!item) {
                    return;
                }
                const clonedItem = { ...item as object };

                if (item[itemIsInstantiatedSymbol]) {
                    let clonedInstance = instantiatedClones[associationKey][id];
                    if (!clonedInstance) {
                        clonedInstance = await item[instanceSymbol].clone(instantiatedClones, clonedObject);
                    }
                    clonedItem[itemIsInstantiatedSymbol] = true;
                    clonedItem[instanceSymbol] = clonedInstance;
                }
                if (clonedObject.cache) {
                    clonedObject.cache.reverseAssociations.add(clonedObject, clonedItem[constructorSymbol].getModelName(), id as number | symbol);
                }

                clonedObject.associatedValueItems[associationName][id] = clonedItem;
            });
        });
    }

    /**
     * Save cloned object to instantiatedClones to avoid cloning it again.
     *
     * @param {ComplexData} clonedObject
     * @param {{}} instantiatedClones
     */
    private rememberClonedInstance(clonedObject: ComplexData<T>, instantiatedClones: AssociationNameToComplexDataArray): void {
        const { id } = clonedObject.data;
        const key = (<any>clonedObject.constructor).associationKey;
        instantiatedClones[key] = instantiatedClones[key] || {};
        instantiatedClones[key][id] = clonedObject;
    }

    /**
     * Clone single object without associations
     *
     * @param {ComplexData} parent
     * @returns {Promise<ComplexData>}
     */
    private async createClonedObject(parent: ComplexData<T> = null): Promise<this> {
        const clonedObject = await this.constructor.build(this.getDataWithState(), { [skipDefaultsSymbol]: true }, {
            parent,
            listeners: !parent ? this.getRootListenerMap(BaseData.EXTERNAL_EVENTS) : {},
            cache: parent ? parent.cache : new (this.cache ? this.cache.constructor as any : Cache)(),
        });
        clonedObject.associatedValueItems = {};
        // @ts-ignore
        clonedObject.extraData[skipDefaultsSymbol] = null;
        return clonedObject as unknown as this;
    }

    public async copyFrom(sourceItem: ComplexData<T>): Promise<void> {
        return this.updateAllAssociatedValues(sourceItem);
    }

    private getDataWithState(): DataObject {
        return {
            [constructorSymbol]: this.constructor,
            ...this.data.getDataValues(),
            ...(this.isNew ? { [isNewSymbol]: true } : {}),
            ...(this.isDeleted ? { [isDeletedSymbol]: true } : {}),
            ...(this.isUpdated ? { [isUpdatedSymbol]: true } : {}),
        };
    }

    private sameItemIncludedInDeletedAssociations(associationInstance: ComplexData<any>): Boolean {
        return this.deletedAssociatedItems.some(deletedItem => deletedItem.constructor === associationInstance.constructor
            && deletedItem.data.id === associationInstance.data.id);
    }

    private async updateAllAssociatedValues(clone: ComplexData<T>, checkedValues: UniqueIdStorage = new UniqueIdStorage()): Promise<void> {
        this.update(clone);
        const associatedValues = Object.entries(clone.associatedValues).filter(([, items]) => Boolean(items));

        await Bluebird.each(associatedValues, (async ([associationName, items]: [string, ComplexData<T>[]]) => {
            const association = this.constructor.allowedAssociations[associationName];
            if (items && this.associatedValues[associationName]) {
                await Bluebird.each(this.associatedValues[associationName], (async associationInstance => {
                    if (clone.sameItemIncludedInDeletedAssociations(associationInstance)) {
                        await associationInstance.delete();
                        if (!checkedValues.has(associationInstance)) {
                            checkedValues.add(associationInstance);
                        }
                    }
                }));
            }

            await Bluebird.each(items, (async sourceItem => {
                const instance = this.createItemInstance(sourceItem.getDataWithState(), {});

                if (association.instance === instance.constructor) {
                    this.updateItemInAssociation(associationName, sourceItem, instance);
                }

                if (!checkedValues.has(instance)) {
                    checkedValues.add(instance);
                    await instance.updateAllAssociatedValues(sourceItem, checkedValues);
                }
            }));
        }));
    }

    private updateItemInAssociation(associationName: string, sourceItem: ComplexData<T>, instance: ComplexData<any>): void {
        const associatedItem = this.associatedValueItems[associationName] && this.associatedValueItems[associationName][sourceItem.data.id];

        if (!associatedItem) {
            const isSingle = (this.constructor.allowedAssociations[associationName] as AssociationDefinitionSingle<any, any>).single !== undefined
                ? (this.constructor.allowedAssociations[associationName] as AssociationDefinitionSingle<any, any>).single
                : false;
            this.addNewAssociatedItem({
                associationName,
                ...(this.constructor as any).convertItemToAssociatedValueItem({
                    item: instance,
                    ItemConstructor: sourceItem.constructor,
                }),
                isSingle,
            });
            this.noticeAssociationChange(associationName);
        }
    }

    public async addAssociationIfMatchesConditions(associationName: string, data: DataObject): Promise<void> {
        const association = this.constructor.allowedAssociations[associationName];
        if (!this.itemMatchesAllConditions(data, association.condition)) {
            return;
        }

        this.removeDeletedAssociation(association.instance as any, data.id);

        await this[`${(association as AssociationDefinitionSingle<any, any>).single ? 'set' : 'add'}${(<any> this.constructor).getMethodName(associationName, association, false)}`](data);
    }

    /**
     * addNewAssociatedItem and removeAssociatedItems functions can take either a ComplexData
     * instance or an object as their 'item' argument. This function is used to detect
     * which type is passed in and convert to the appropriate format.
     *
     * @param {DataObject | ComplexData} item
     * @param {Function} ItemConstructor
     */
    private static convertItemToAssociatedValueItem({
        item,
        ItemConstructor,
    }: {
        item: DataObject | ComplexData<any>,
        ItemConstructor: typeof ComplexData
    }) {
        if (!item || !hasMixin(item, ComplexData)) {
            return { item, ItemConstructor };
        }
        if (!(item instanceof ItemConstructor)) {
            throw new TypeError(`Type mismatch, ${(item as any).constructor.name} should be a(n) ${ItemConstructor.getModelName()}`);
        }
        return {
            ItemConstructor,
            item: {
                ...item.data.getDataValues(),
                [instanceSymbol]: item,
                [itemIsInstantiatedSymbol]: true,
            },
        };
    }

    public async getSearchableFields(): Promise<string[]> {
        return [];
    }

    public async copyData(_from: ComplexData<T>) {
        return this;
    }

    /**
     * Definition of class methods automatically created for all associations
     *
     * @returns {[null,null]}
     */
    static getAssociationMethodDefinitions(): MethodDefinition[] {
        return [{
            prefix: 'addAll',
            isPlural: true,
            isSingle: false,
            handler({
                ItemConstructor,
                associationName,
                isSingle,
            }, items: (DataObject | ComplexData<any>)[], extraData = {}) {
                if (!Array.isArray(items)) {
                    throw new TypeError('Argument must be an array');
                }
                const ids = items.map(item => this.addNewAssociatedItem({
                    ...this.constructor.convertItemToAssociatedValueItem({ item, ItemConstructor }),
                    associationName,
                    isSingle,
                    extraData,
                }));

                const options = {
                    filter: ids.map(id => ({
                        operator: '=',
                        property: 'id',
                        value: id,
                    })),
                };
                this.markAssociationLoaded(associationName, options);
                return this.getAssociatedValue(associationName, options).filter(item => ids.includes(item.data.id));
            },
        }, {
            prefix: 'add',
            isPlural: false,
            isSingle: false,
            handler({ ItemConstructor, associationName, isSingle }, item: DataObject | ComplexData<any>, extraData = {}) {
                const id = this.addNewAssociatedItem({
                    ...this.constructor.convertItemToAssociatedValueItem({ item, ItemConstructor }),
                    associationName,
                    isSingle,
                    extraData,
                });
                const options = {
                    filter: [{
                        operator: '=',
                        property: 'id',
                        value: id,
                    }],
                };
                const associatedValuesKey = this.constructor.allowedAssociations[associationName].key;
                this.markAssociationLoaded(associatedValuesKey, options);
                return this.getAssociatedValueById(associationName, id, options);
            },
        }, {
            prefix: 'remove',
            isPlural: false,
            isSingle: false,
            handler({ ItemConstructor, associationName, isSingle }, item: DataObject | ComplexData<any>, extraData = {}) {
                return this.removeAssociatedItem({
                    ...this.constructor.convertItemToAssociatedValueItem({ item, ItemConstructor }),
                    associationName,
                    isSingle,
                    extraData,
                });
            },
        }, {
            prefix: 'set',
            isPlural: false,
            isSingle: true,
            handler({ ItemConstructor, associationName, isSingle }, item: DataObject | ComplexData<any>, extraData = {}) {
                if (item.isDeleted) {
                    console.log('Cannot set deleted item as association');
                    throw new Error('Cannot set an item that is marked as deleted');
                }
                const id = this.addNewAssociatedItem({
                    ...this.constructor.convertItemToAssociatedValueItem({ item, ItemConstructor }),
                    associationName,
                    isSingle,
                    extraData,
                });
                const options = {
                    filter: [{
                        operator: '=',
                        property: 'id',
                        value: id,
                    }],
                };
                const associatedValuesKey = this.constructor.allowedAssociations[associationName].key;
                this.markAssociationLoaded(associatedValuesKey, options);
                const associatedValues = this.getAssociatedValue(associationName, options);

                if (associatedValues instanceof Promise || associatedValues.then) {
                    return associatedValues.then(result => result[0]);
                }

                return associatedValues[0];
            },
        }, {
            prefix: 'unset',
            isPlural: false,
            isSingle: true,
            handler({ ItemConstructor, associationName, isSingle }, item: DataObject | ComplexData<any>, extraData = {}) {
                return this.removeAssociatedItem({
                    ...this.constructor.convertItemToAssociatedValueItem({ item, ItemConstructor }),
                    associationName,
                    isSingle,
                    extraData,
                });
            },
        }, {
            prefix: 'get',
            isPlural: false,
            isSingle: true,
            async handler({ associationName }) {
                const associatedValues = this.associatedValues[associationName] || await this.getAssociatedValue(associationName);
                if (this.disposable) {
                    const Constructor = this.constructor.allowedAssociations[associationName].instance;
                    const newItemInstance = associatedValues[0] ? new Constructor(associatedValues[0], {}, {
                        parent: this,
                        disposable: true,
                        cache: this.cache,
                    }) : null;
                    if (newItemInstance) {
                        this.setupEventForwarding(newItemInstance);
                    }
                    return newItemInstance;
                }
                return associatedValues[0] || null;
            },
        }, {
            prefix: 'get',
            isPlural: false,
            isSingle: false,
            async handler({ associationName }, index: number) {
                const associatedValues = await this.getAssociatedValue(associationName);
                if (this.disposable && associatedValues[index]) {
                    const Constructor = this.constructor.allowedAssociations[associationName].instance;
                    const newItemInstance = new Constructor(associatedValues[index], {}, {
                        parent: this,
                        disposable: true,
                        cache: this.cache,
                    });
                    this.setupEventForwarding(newItemInstance);
                    return newItemInstance;
                }
                return associatedValues[index] || null;
            },
        }, {
            prefix: 'getAll',
            isPlural: true,
            isSingle: false,
            async handler({ associationName }, options = {}) {
                const associatedValues = await this.getAssociatedValue(associationName, options);
                if (this.disposable) {
                    const Constructor = this.constructor.allowedAssociations[associationName].instance;
                    return (associatedValues || []).map(value => {
                        const newItemInstance = new Constructor(value, {}, {
                            parent: this,
                            disposable: true,
                            cache: this.cache,
                        });
                        this.setupEventForwarding(newItemInstance);
                        return newItemInstance;
                    });
                }
                return associatedValues || [];
            },
        }, {
            prefix: 'get',
            postfix: 'ById',
            isPlural: false,
            isSingle: false,
            async handler({ associationName }, id: number | symbol) {
                return this.getAssociatedValueById(associationName, id);
            },
        }, {
            prefix: 'get',
            postfix: 'Count',
            isPlural: false,
            isSingle: false,
            noInstanceNeeded: true,
            async handler({ associationName }, filters) {
                return this.getAssociatedValueCount(associationName, filters);
            },
        }];
    }
}

export type ComplexDataType<CT extends EntityWithId> = ComplexData<CT>;
export type ComplexDataClassType = typeof ComplexData;
