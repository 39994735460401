const modelProperties = {
    modelName: 'Note',
    tableName: 'Note',

    associations: [{
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }],

    validations: { title: { type: 'length', max: 45 } }, 
};

export default modelProperties;
