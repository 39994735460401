import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Select from '@components/fields/Select';

import { useRecoilValue } from 'recoil';

import documentsListColumnConfig from '@features/document/list/documentsListColumnConfig';
import useComplexData from '@data/hooks/complexDataHook';
import useQuoteName from '@data/hooks/quoteName';
import DOCUMENT from '@powerednow/shared/constants/document';

import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import DocumentEntity from '@powerednow/shared/modules/complexData/document/entity';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import Contact from '@powerednow/shared/modules/complexData/contact';
import { Filter } from '@powerednow/shared/modules/complexData/connectedData';
import DataGridTabList, { ComplexTabListItems, gridFilterToComplexFilter, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import { displayProjectsOnPortal } from '@data/state/companySettings';

export type DocumentListItem = ModelFields<DocumentEntity>

const userRelevantDocumentTypes = {
    [DOCUMENT.TYPES_VALUES.INVOICE]: 'Invoice',
    [DOCUMENT.TYPES_VALUES.QUOTE]: 'Quote',
    [DOCUMENT.TYPES_VALUES.CREDITNOTE]: 'Credit Note',
    [DOCUMENT.TYPES_VALUES.PURCHASE_ORDER]: 'Purchase Order',
    [DOCUMENT.TYPES_VALUES.WORKSHEET]: 'Job sheet',
};// list of user docs types

const useDocuments = (params: TabListItemsParams<DocumentEntity>): ComplexTabListItems<DocumentEntity> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
        selectedFilter,
        filters,
    } = params;
    const { optionalResult, error } = useComplexData<ContactEntity, { listItems: DocumentListItem[], loaded: boolean, total: number }, Contact>(
        Contact,
        [contactId, pageSize, skip, selectedFilter, sorters, filters],
        async (complexContact, resultSetter) => {
            const complexCustomer = await complexContact.getCustomer();
            const complexFilters = (filters?.items || []).map(gridFilterToComplexFilter);
            complexFilters.push({
                operator: '=',
                property: 'confirmed',
                // @ts-ignore
                value: true,
            });
            complexFilters.push({
                operator: '>',
                property: 'status',
                // @ts-ignore
                value: DOCUMENT.STATUS.RAISED,
            });

            if (selectedFilter === -1) {
                complexFilters.push({
                    operator: 'in',
                    property: 'type',
                    // @ts-ignore
                    value: [
                        DOCUMENT.TYPES_VALUES.INVOICE,
                        DOCUMENT.TYPES_VALUES.QUOTE,
                        DOCUMENT.TYPES_VALUES.CREDITNOTE,
                        DOCUMENT.TYPES_VALUES.PURCHASE_ORDER,
                        DOCUMENT.TYPES_VALUES.WORKSHEET,
                    ],

                });
            } else {
                complexFilters.push({
                    operator: '=',
                    property: 'type',
                    // @ts-ignore
                    value: selectedFilter,
                });
            }

            // @ts-ignore
            const total = await complexCustomer?.getDocumentCount(complexFilters);
            const complexDocuments = await complexCustomer?.getAllDocument({
                skip,
                // @ts-ignore
                filters: complexFilters,
                limit: pageSize,
                sorters,
            });
            resultSetter(complexDocuments, {
                total,
                loaded: true,
                listItems: complexDocuments.map(document => document.data.getPureDataValues()),
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

const sortModel = [
    { field: 'dt_created', sort: 'desc' } as const,
];

export default function DocumentsList() {
    const rowHeight = 80;
    const height = 510;
    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = documentsListColumnConfig(displayProjects);

    const [selectedFilter, setSelectedFilter] = useState<number>(-1);
    const quoteLabel = useQuoteName().charAt(0).toUpperCase() + useQuoteName().slice(1);
    const filterList = [
        [-1, 'All'], 
        ...Object.entries(userRelevantDocumentTypes)].map(([id, name]) => ({ id: Number(id), text: Number(id) === DOCUMENT.TYPES_VALUES.QUOTE ? quoteLabel : name }));

    const handleDocumentTypeSelectionChange = e => {
        setSelectedFilter(Number(e.target.value));
    };

    return (
        <Box>
            <Select
                value={selectedFilter}
                label="Document Type:"
                helperText=""
                onChange={handleDocumentTypeSelectionChange}
                items={filterList}
                labelPosition="left"
            />
            <DataGridTabList
                selectedFilter={selectedFilter}
                columns={columns}
                useGetListItems={useDocuments}
                sortModel={sortModel}
                rowHeight={rowHeight}
                height={height}
                noRowsText="Sorry, there are no documents to display"
                tabToNavigate="document"
            />
        </Box>
    );
}
