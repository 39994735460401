import { DateOrString, EntityWithCompanyId, field } from '../entity';

const dateUtils = require('modules/utilities/date');

export default class UserToContactEntity extends EntityWithCompanyId {
    @field({ type: 'int', primaryKey: true, autoIncrement: true })
    public id?: number;

    @field({ type: 'int' })
    public company_id: number;

    @field({ type: 'string', dbType: 'date', defaultValue: dateUtils.getNow.bind(dateUtils) })
    public dt_created?: DateOrString;

    @field({
        type: 'boolean', codeName: 'isDeleted', allowNull: false, defaultValue: false, serverOnly: true,
    })
    public isdeleted?: boolean;

    @field({ type: 'int', allowNull: false })
    public contact_id: number;

    @field({ type: 'string', allowNull: false })
    public user_uuid?: string;
}
