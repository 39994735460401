const modelProperties = {
    modelName: 'FileGroup',
    tableName: 'FileGroup',
    minAppVersion: '8.0.0',
    associations: [{
        type: 'hasMany',
        model: 'File',
        parameters: { foreignKey: { name: 'filegroup_id', fieldName: 'filegroup_id' } },
        followFor: ['Archive'],
        includeRestores: ['FileGroup'],
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }],
    validations: { title: { type: 'length', max: 60 } },
};

export default modelProperties;
