import React, { useEffect, useMemo } from 'react';
import DataGrid, { Columns } from '@components/dataGrid/DataGrid';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '@data/state/auth';
import { useNavigate } from 'react-router-dom';
import { pagerIndex } from '@data/state/pagers';
import useSorter from '@data/hooks/sorter';
import useFilter from '@data/hooks/filter';

import { type Sorter } from '@powerednow/shared/modules/complexData/connectedData';
import { type EntityWithCompanyId, type ModelFields } from '@powerednow/shared/modules/complexData/entity';
import {
    type DataGridProps, type GridSortModel, type GridFilterModel, getGridStringOperators, GridColTypeDef, GridFilterItem,
} from '@material-ui/data-grid';

const projectDescriptionColumnType: GridColTypeDef = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter(operator => ['contains', 'startsWith', 'endsWith'].includes(operator.value)),
};

const columnTypes = { projectDescription: projectDescriptionColumnType };

export interface TabListItemsParams<T extends EntityWithCompanyId> {
    contactId: number | null;
    pageSize: number;
    skip: number;
    selectedFilter?: number;
    sorters: Sorter<T>[]
    filters?: GridFilterModel;
}

export type ComplexTabListItems<T extends EntityWithCompanyId> = {
    listItems: Partial<ModelFields<T>>[],
    error: any,
    loaded: boolean,
    total: number,
};

export type GetListItemsHook<T extends EntityWithCompanyId> = (_params: TabListItemsParams<T>) => ComplexTabListItems<T>

type DataGridTabListProps<T extends EntityWithCompanyId> = {
    columns: Columns,
    rowHeight: DataGridProps['rowHeight'],
    height: number,
    pageSize?: number,
    noRowsText: string,
    sortModel: Sorter<T>[],
    useGetListItems: GetListItemsHook<T>,
    selectedFilter?: number,
    tabToNavigate: string,
    filterModel?: GridFilterModel,
}

export const gridFilterToComplexFilter = (filter: GridFilterItem) => {
    const operatorMap = {
        '=': '=',
        '<': '<',
        '>': '>',
        '<=': '<=',
        '>=': '>=',
        '!=': '!=',
        contains: 'like',
        startsWith: 'like',
        endsWith: 'like',
    };
    let value = filter.value || '';
    if (filter.operatorValue === 'contains') {
        value = `%${value}%`;
    } else if (filter.operatorValue === 'startsWith') {
        value = `${value}%`;
    } else if (filter.operatorValue === 'endsWith') {
        value = `%${value}`;
    }

    return {
        property: filter.columnField,
        operator: operatorMap[filter.operatorValue || '='],
        value: encodeURIComponent(value),
    };
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export default function DataGridTabList<T extends EntityWithCompanyId>(props: DataGridTabListProps<T>) {
    const {
        columns,
        rowHeight,
        height,
        noRowsText,
        pageSize,
        sortModel,
        useGetListItems,
        selectedFilter,
        tabToNavigate,
    } = props;
    const [page, setPage] = useRecoilState(pagerIndex({ name: 'listPager', id: 0, default: 0 }));
    const [sorters, setSorters] = useSorter<Sorter<T>[]>(sortModel);
    const [filters, setFilters] = useFilter<GridFilterModel>();
    const authData = useRecoilValue(authState);
    const navigate = useNavigate();
    const { data: { contactId }, portalId } = authData || {};
    const customPageSize = pageSize || 5;
    const skip = customPageSize * page;

    const {
        listItems,
        loaded,
        total,
        error,
    } = useGetListItems({
        contactId,
        pageSize: customPageSize,
        skip,
        sorters,
        selectedFilter,
        filters,
    });

    const rows = loaded ? listItems : [];
    const isPageLoaded = loaded ? page : 0;

    const handlePageChange = pageData => {
        if (loaded) {
            setPage(pageData.page || pageData);
        }
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        setSorters(newModel as Sorter<T>[]);
    };

    const handleRowClick = ({ row }) => {
        navigate(`/portal/${portalId}/${tabToNavigate}/${row.id}/view`);
        scrollToTop();
    };

    const handleFilterModelChange = (newModel: GridFilterModel) => {
        setFilters(newModel);
    };

    useEffect(() => {
        setPage(0);
    }, [selectedFilter, filters]);

    const updatedColumns = useMemo(() => columns.map(column => ({ ...column, ...(!column.filterable ? { filterable: false } : {}) })), [columns]);

    return (
        <DataGrid
            error={error}
            columns={updatedColumns}
            rows={rows}
            rowCount={total}
            disableColumnSelector
            rowCursor="pointer"
            noRowBorder
            noHeaderSeparator
            rowHeight={rowHeight}
            height={height}
            pageSize={customPageSize}
            loading={!loaded}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            noRowsText={noRowsText}
            onRowClick={handleRowClick}
            page={isPageLoaded}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            columnTypes={columnTypes}
        />
    );
}
