import { field } from '../entity';
import ActionEntity from '../action/entity';

export default class ActionsForCustomerEntity extends ActionEntity {
    @field({ type: 'int' })
    public customer_id?: number;

    @field({ type: 'boolean' })
    public is_sent?: boolean;

    @field({ type: 'string' })
    public project_description?: string;
}
