import React, { useMemo } from 'react';
import { Columns } from '@components/dataGrid/DataGrid';
import { Box, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import {
    parse,
    patterns,
    isValid,
    format,
    convertToLocal,
} from '@powerednow/shared/modules/utilities/date';
import AppointmentBadge from '@features/appointment/badge/AppointmentBadge';
import AssignedUsers from '@features/appointment/assignedUsers/AssignedUsers';

const dtStartCell = ({ row }) => {
    let content: string | React.ReactNode = ' Unplanned ';

    if (isValid(row.dt_start)) {
        const date = parse(row.dt_start, patterns.messageDateTime);
        const finalDate = convertToLocal(date);
        content = (
            <>
                <CalendarIcon />
                &nbsp;
                {format(finalDate, patterns.shortDateWithTime)}
            </>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            lineHeight={3}
        >
            {content}
        </Box>
    );
};

const timeDiffCell = ({ row }) => {
    let content: string | React.ReactNode = ' - ';

    if (isValid(row.dt_start)) {
        const startDate = parse(row.dt_start, patterns.ISO8601Long);
        const finalStartDate = convertToLocal(startDate);
        const endDate = parse(row.dt_end, patterns.ISO8601Long);
        const finalEndDate = convertToLocal(endDate);

        content = <AppointmentBadge startDate={finalStartDate} endDate={finalEndDate} />;
    }

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            flex={1}
        >
            {content}
        </Box>
    );
};

function projectCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {row.project_description}
        </Typography>
    );
}

const engineerCell = rowParams => <AssignedUsers appointmentId={rowParams.row.id as number} mode="onlyFirst" />;

export default function appointmentListColumnConfig(displayProjects: boolean): Columns {
    return useMemo(() => {
        const columns: Columns = [
            {
                field: 'dt_start',
                headerName: 'Date',
                minWidth: 170,
                renderCell: dtStartCell,
            },
            {
                field: 'engineer',
                headerName: 'Assignee',
                sortable: false,
                minWidth: 290,
                renderCell: engineerCell,
            },
            {
                field: 'time_diff',
                // in MUI 4 if headerName is empty, the field value is displayed
                headerName: ' ',
                sortable: false,
                minWidth: 120,
                align: 'right',
                renderCell: timeDiffCell,
            },
        ];
        if (displayProjects) {
            columns.push({
                field: 'project_description',
                type: 'projectDescription',
                headerName: 'Project',
                filterable: true,
                flex: 1,
                minWidth: 400,
                renderCell: projectCell,
            });
        }
        return columns;
    }, [displayProjects]);
}
