import React, { Suspense, useMemo } from 'react';
import Badge from '@components/Badge/Badge';
import { Columns } from '@components/dataGrid/DataGrid';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { capitalize } from 'lodash';
import { DOCUMENT } from '@powerednow/shared/constants';
import {
    parse,
    patterns,
    format,
    convertToLocal,
} from '@powerednow/shared/modules/utilities/date';
import { useProjectDescription } from '@powerednow/portal/src/helper/tabList';
import Typography from '@material-ui/core/Typography';
import { setBadgeColor, isInvoicePaid } from './documentsListHelpers';

const documentStatusMap = {
    [DOCUMENT.STATUS.ACCEPTED]: 'Accepted',
    [DOCUMENT.STATUS.REJECTED]: 'Rejected',
    [DOCUMENT.STATUS.SENT]: 'Sent',
    [DOCUMENT.STATUS.PAID]: 'Paid',
    // refunded credit note will be stated as paid(both have value 4)
    // [constants.DOCUMENT.STATUS.REFUNDED]: 'Refunded',
};

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flex: 1,
        lineHeight: '35px',
    },
    spanFont: {
        fontSize: 'x-small',
    },
}));

function DateCell({ row }) {
    const date = parse(row.dt_created, patterns.messageDateTime);
    const finalDate = convertToLocal(date);

    return (
        <span>
            {format(finalDate, patterns.shortDateOnly)} 
        </span>
    );
}

function projectCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {useProjectDescription(row.job_id)}
        </Typography>
    );
}

function StatusCell({ row }) {
    const classes = useStyles();
    const documentStatus: number = row.status;
    const badgeColor = setBadgeColor(documentStatus);
    const badgeChildContent = row.displaynumber;
    const badgeContent = documentStatusMap[row.status] || '';
    const shouldBadgeBeInvisible = badgeContent === '';

    return (
        <Box className={classes.root}>
            <Box>
                <Badge
                    size="small"
                    content={capitalize(badgeContent)}
                    color={badgeColor}
                    invisible={shouldBadgeBeInvisible}
                >
                    {badgeChildContent}
                </Badge>
            </Box>
        </Box>
    );
}

export default function documentsListColumnConfig(displayProjects: boolean): Columns {
    return useMemo(() => {
        const columns: Columns = [{
            field: 'dt_created',
            headerName: 'Date',
            minWidth: 60,
            renderCell: DateCell,
        }, {
            field: 'displaynumber',
            headerName: 'Details',
            headerAlign: 'left',
            minWidth: 120,
            filterable: true,
            renderCell: StatusCell,
        }];
        if (displayProjects) {
            columns.push({
                field: 'project',
                headerName: 'Project',
                sortable: false,
                flex: 1,
                minWidth: 400,
                renderCell: projectCell,
            });
        }
        return columns;
    }, [displayProjects]);
}
