import { MESSAGES } from '@powerednow/shared/constants';

const modelProperties = {
    modelName: 'CustomerMessage',
    tableName: 'CustomerMessage',
    minAppVersion: '9.0.0',
    associations: [{
        type: 'hasMany',
        model: 'CustomerMessageAttachment',
        parameters: {
            complexAssociationName: 'customerMessageAttachment',
            foreignKey: {
                name: 'customermessage_id',
                fieldName: 'customermessage_id',
            },
        },
        followFor: ['Archive'],
        includeRestores: ['CustomerMessage'],
    }, {
        type: 'hasMany',
        model: 'MessageRecipient',
        parameters: {
            foreignKey: {
                name: 'message_id',
                fieldName: 'message_id',
            },
        },
        followFor: ['Archive'],
        includeRestores: ['CustomerMessage'],
    }, {
        type: 'hasMany',
        model: 'CustomerMessage',
        parameters: {
            foreignKey: {
                name: 'parent_id',
                fieldName: 'parent_id',
            },
        },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'CustomerMessage',
        parameters: {
            foreignKey: {
                name: 'parent_id',
                fieldName: 'parent_id',
            },
        },
    }, {
        type: 'belongsTo',
        model: 'User',
        parameters: { foreignKey: { name: 'user_id', fieldName: 'user_id' } },
    }, {
        type: 'belongsTo',
        model: 'Job',
        parameters: { foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }, {
        type: 'belongsTo',
        model: 'Company',
        parameters: { foreignKey: { name: 'company_id', fieldName: 'company_id' } },
    }, {
        type: 'belongsTo',
        model: 'Action',
        parameters: { as: 'Action', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { linked_type: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.APPOINTMENT },
    }, {
        type: 'belongsTo',
        model: 'Document',
        parameters: { as: 'Document', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { linked_type: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT },
    }, {
        type: 'belongsTo',
        model: 'FormDocument',
        parameters: { as: 'FormDocument', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { linked_type: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.FORMDOCUMENT },
    }, {
        type: 'belongsTo',
        model: 'Payment',
        parameters: { as: 'Payment', foreignKey: { name: 'linked_id', fieldName: 'linked_id' } },
        where: { linked_type: MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.PAYMENT },
    }],
    validations: {

    },
};

export default modelProperties;
