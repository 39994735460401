const modelProperties = {
    modelName: 'Message',
    tableName: 'Message',
    associations: [{
        type: 'hasMany',
        model: 'MessageAttachments',
        parameters: { foreignKey: { name: 'message_id', fieldName: 'message_id' } },
        followFor: ['Archive'],
    }, {
        type: 'belongsTo',
        model: 'Action',
        parameters: { as: 'Action', foreignKey: { name: 'action_id', fieldName: 'action_id' } },
    }, {
        type: 'hasMany',
        model: 'Message',
        parameters: { foreignKey: { name: 'parent_id', fieldName: 'parent_id' } },
    }, {
        type: 'belongsTo',
        model: 'Message',
        parameters: { foreignKey: { name: 'parent_id', fieldName: 'parent_id' } },
    }, {
        type: 'belongsTo',
        serverOnly: true,
        model: 'Job',
        parameters: { as: 'Job', foreignKey: { name: 'job_id', fieldName: 'job_id' } },
        followFor: ['ParentCheck'],
    }],
    validations: { recipient: { type: 'length', max: 250 }, type: { type: 'length', max: 25 } },
};

export default modelProperties;
