import {
    ChannelTypes,
    ContactMethodTypeMap,
    MessageContentDefinition,
} from '@powerednow/shared/constants/customerEmailTemplateValues';
import ComplexData, { AssociationConfig, AssociationDefinitionSingle, AutoGeneratedFunctions } from '../complexData';
import CustomerMessage from '../customerMessage';
import CustomerMessageEntity from '../customerMessage/entity';
import MessageRecipientEntity, {
    MessageDeliveryTemplateData, DELIVERY_STATUS_CATEGORY, MessageStatusTypeId, MessageStatusCategory,
} from './entity';
import MessageTemplate from '../messageTemplate';
import MessageTemplateEntity from '../messageTemplate/entity';
import modelProperties from './modelProperties';
import ContactEntity from '../contact/entity';
import Contact from '../contact';
import CompanyEntity from '../company/entity';
import Company from '../company';
import { MESSAGES } from '../../../constants';

export type MessageTemplateValues = {
    subject: string,
    message: string,
}

interface MessageRecipientAssociations extends AssociationConfig<any, any> {
    message: AssociationDefinitionSingle<CustomerMessageEntity, CustomerMessage>
    messageTemplate: AssociationDefinitionSingle<MessageTemplateEntity, MessageTemplate>
    contact: AssociationDefinitionSingle<ContactEntity, Contact>
    company: AssociationDefinitionSingle<CompanyEntity, Company>
}

interface MessageRecipient extends AutoGeneratedFunctions<MessageRecipientAssociations, MessageRecipientEntity, ComplexData<MessageRecipientEntity>> {
}

// eslint-disable-next-line no-redeclare
class MessageRecipient extends ComplexData<MessageRecipientEntity> {
    static Entity = MessageRecipientEntity;

    static modelProperties = modelProperties;

    public static get allowedAssociations(): MessageRecipientAssociations {
        return {
            message: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().message_id,
                },
            },
            messageTemplate: {
                key: 'messageTemplate',
                instance: MessageTemplate,
                entity: MessageTemplateEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().message_template_id,
                },
            },
            contact: {
                key: 'contact',
                instance: Contact,
                entity: ContactEntity,
                single: true,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().contact_id,
                },
            },
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getFieldSymbols().company_id,
                },
            },
        };
    }

    public getChannelType(): ChannelTypes {
        return ContactMethodTypeMap[this.data.contactmethodtype_id];
    }
    
    public setTemplateData(data: MessageDeliveryTemplateData) {
        this.data.templateData = {
            ...data,
        };
    }

    public async getTemplateValuesForRendering(): Promise<MessageTemplateValues> {
        if (this.data.subject !== '' || this.data.message !== '') {
            const { subject, message } = this.data;
            return { subject, message };
        }
        return this.getTemplateValues();
    }

    public async getTemplateValues(): Promise<MessageTemplateValues> {
        const template = await this.getMessageTemplate();
        if (template) {
            return {
                subject: template.data.subject,
                message: template.data.message,
            };
        }
        const content = await this.getDefaultContent();
        return {
            subject: content.SUBJECT?.TEXT,
            message: content.BODY?.TEXT,
        };
    }

    public async getButtonText(renderer: (_templateSource, _data, _languageCode?) => string) {
        const content = await this.getDefaultContent();        
        const combinedTemplateData = await this.getTemplateDataForRendering();

        if ((content.HIDE_ACTION && !combinedTemplateData.canUsePortal)
            || (content.HIDE_ACTION && !combinedTemplateData.isMainContact && !combinedTemplateData.canSiteContactAccessPreviousMessages)) {
            return '';
        }
        return content?.BODY?.BUTTON_TEXT ? renderer(content.BODY.BUTTON_TEXT, combinedTemplateData) : '';
    }

    public getStatusCategory(statusId: MessageStatusTypeId = this.data.status): MessageStatusCategory {
        const map: Record<MessageStatusCategory, MessageStatusTypeId[]> = {
            [DELIVERY_STATUS_CATEGORY.process_failed]: [
                MESSAGES.DELIVERY_STATUS.process_failed,
            ],
            [DELIVERY_STATUS_CATEGORY.processed]: [
                MESSAGES.DELIVERY_STATUS.processed,
            ],
            [DELIVERY_STATUS_CATEGORY.sent]: [
                MESSAGES.DELIVERY_STATUS.send,
                MESSAGES.DELIVERY_STATUS.sent,
                MESSAGES.DELIVERY_STATUS.delivered,
            ],
            [DELIVERY_STATUS_CATEGORY.rejected]: [
                MESSAGES.DELIVERY_STATUS.bounce,
                MESSAGES.DELIVERY_STATUS.dropped,
                MESSAGES.DELIVERY_STATUS.deferral,
                MESSAGES.DELIVERY_STATUS.hard_bounce,
                MESSAGES.DELIVERY_STATUS.soft_bounce,
                MESSAGES.DELIVERY_STATUS.failed,
                MESSAGES.DELIVERY_STATUS.reject,
            ],
            [DELIVERY_STATUS_CATEGORY.open]: [
                MESSAGES.DELIVERY_STATUS.read,
                MESSAGES.DELIVERY_STATUS.open,
            ],
            [DELIVERY_STATUS_CATEGORY.click]: [
                MESSAGES.DELIVERY_STATUS.click,
            ],
            [DELIVERY_STATUS_CATEGORY.reported]: [
                MESSAGES.DELIVERY_STATUS.spam,
                MESSAGES.DELIVERY_STATUS.report_span,
                MESSAGES.DELIVERY_STATUS.unsubscribe,
                MESSAGES.DELIVERY_STATUS.unsub,
            ],
            [DELIVERY_STATUS_CATEGORY.deferred]: [
                MESSAGES.DELIVERY_STATUS.deferred,
            ],
        };
        let foundKey;
        Object.keys(map).forEach(key => {
            if (map[key].includes(statusId)) {
                foundKey = key;
            }
        });
        return foundKey;
    }

    private async getDefaultContent(): Promise<MessageContentDefinition> {
        const message = await this.getMessage();
        const content = message.findDefaultContentById();
        return content[ContactMethodTypeMap[this.data.contactmethodtype_id]] || content.EMAIL;
    }

    private async getLocaleTemplateData() {
        const company = await this.getCompany();
        const companyAddress = await company.getCompanyAddress();
        const { city } = companyAddress.data;
        const country = await companyAddress.getCountry();
        return { city, countryCode: country.data.code };
    }

    public async getRenderedContent(renderer: (_templateSource, _data, _languageCode?) => string): Promise<MessageTemplateValues> {
        const content = await this.getTemplateValuesForRendering();
        const combinedTemplateData = await this.getTemplateDataForRendering();
        return {
            message: content.message ? renderer(content.message, combinedTemplateData) : '',
            subject: content.subject ? renderer(content.subject, combinedTemplateData) : '',
        };
    }

    public async getTemplateDataForRendering() {
        const message = await this.getMessage();
        const { templateData } = this.data;
        //
        // Some older messages  may not have previousValues or documenturl template data. Lets make sure we are not
        // crashing the app if we try to render a template with no data.
        //
        if (!templateData.previousValues) {
            templateData.previousValues = {};
        }
        if (!templateData.documenturl) {
            templateData.documenturl = '';
        }
        return {
            ...templateData,
            ...await message.getNotificationTemplateData(),
        };
    }
}

export default MessageRecipient;
