import * as Bluebird from 'bluebird';

import Formatter from 'modules/utilities/formatter';
import * as stripTags from 'striptags';
import ComplexData, {
    AssociationConfig,
    AssociationDefinition,
    AssociationDefinitionSingle,
    AutoGeneratedFunctions,
} from '../complexData';
import ContactMethod from '../contactMethod';
import Entity, { ModelCreationFields } from '../entity';
import Document from '../document';
import FormDocument from '../formDocument';
import Contact, { ContactTemplateData } from '../contact';
import Address, { AddressTemplateData } from '../address';
import ContactToAddress from '../contactToAddress';
import Job from '../job';
import CisRate from '../cisRate';
import ValidationResult from '../../validation/validationResult';
import Company from '../company';
import WhiteLabelToCustomer from '../whiteLabelToCustomer';
import WhiteLabel from '../whiteLabel';
import CustomerEntity from './entity';
import modelProperties from './modelProperties';
import ConnectedData from '../connectedData';
import ActionsForCustomer from '../actionsForCustomer';
import ACTION_CONST from '../../../constants/action';
import Message from '../message';
import PaymentsForCustomer from '../paymentsForCustomer';
import CompanyEntity from '../company/entity';
import ContactEntity from '../contact/entity';
import AddressEntity from '../address/entity';
import ContactToAddressEntity from '../contactToAddress/entity';
import CisRateEntity from '../cisRate/entity';
import DocumentEntity from '../document/entity';
import FormDocumentEntity from '../formDocument/entity';
import JobEntity from '../job/entity';
import WhiteLabelToCustomerEntity from '../whiteLabelToCustomer/entity';
import PaymentsForCustomerEntity from '../paymentsForCustomer/entity';
import ActionsForCustomerEntity from '../actionsForCustomer/entity';
import MessageEntity from '../message/entity';
import CustomerMessageEntity from '../customerMessage/entity';
import CustomerMessage from '../customerMessage';
import LastMessageInThreadEntity from '../lastMessageInThread/entity';
import LastMessageInThread from '../lastMessageInThread';

const constants = require('@powerednow/shared/constants').default;

const _ = require('lodash');

export type CustomerTemplateData = Required<ModelCreationFields<CustomerEntity>> & {
    fullName?: string,
    address?: AddressTemplateData[],
    contacts?: ContactTemplateData[],
    registeredAddress?: string,
    mapAddress?: string,
}

interface CustomerAssociations extends AssociationConfig<any, any> {
    company: AssociationDefinitionSingle<CompanyEntity, Company>
    contact: AssociationDefinition<ContactEntity, Contact>
    address: AssociationDefinition<AddressEntity, Address>
    registeredSite: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    mainSite: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    invoiceSite: AssociationDefinitionSingle<ContactToAddressEntity, ContactToAddress>
    site: AssociationDefinition<ContactToAddressEntity, ContactToAddress>
    contactToAddress: AssociationDefinition<ContactToAddressEntity, ContactToAddress>
    cisRate: AssociationDefinitionSingle<CisRateEntity, CisRate>
    document: AssociationDefinition<DocumentEntity, Document>
    quote: AssociationDefinition<DocumentEntity, Document>
    purchaseOrder: AssociationDefinition<DocumentEntity, Document>
    supplierInvoice: AssociationDefinition<DocumentEntity, Document>
    invoice: AssociationDefinition<DocumentEntity, Document>
    formDocument: AssociationDefinition<FormDocumentEntity, FormDocument>
    job: AssociationDefinition<JobEntity, Job>
    autoJob: AssociationDefinitionSingle<JobEntity, Job>
    nonAutoJob: AssociationDefinition<JobEntity, Job>
    whiteLabelToCustomer: AssociationDefinition<WhiteLabelToCustomerEntity, WhiteLabelToCustomer>
    payment: AssociationDefinition<PaymentsForCustomerEntity, PaymentsForCustomer>
    actionsForCustomer: AssociationDefinition<ActionsForCustomerEntity, ActionsForCustomer>
    appointmentForCustomer: AssociationDefinition<ActionsForCustomerEntity, ActionsForCustomer>
    appointment: AssociationDefinition<ActionsForCustomerEntity, ActionsForCustomer>
    message: AssociationDefinition<MessageEntity, Message>
    customerMessage: AssociationDefinition<CustomerMessageEntity, CustomerMessage>
    lastMessageInThread: AssociationDefinition<LastMessageInThreadEntity, LastMessageInThread>
}

// @ts-ignore
interface Customer extends AutoGeneratedFunctions<CustomerAssociations, CustomerEntity, ComplexData<CustomerEntity>> {
}

// @ts-ignore
// eslint-disable-next-line no-redeclare
class Customer extends ComplexData<CustomerEntity> {
    static Entity = CustomerEntity;

    static modelProperties = modelProperties;

    public async getMainContact(): Promise<Contact> {
        const mainSite = await this.getMainContactToAddress();
        return (mainSite as any).getContact();
    }

    public async setMainContact(...args) {
        const mainSite = await this.getMainContactToAddress();
        return (mainSite as any).setContact(...args);
    }

    public async hasMainContactDescriptionDefined(): Promise<boolean> {
        const mainContact = await this.getMainContact();
        return (mainContact.data.description || '').trim() !== '';
    }

    public async getMainAddress(): Promise<Address> {
        const mainSite = await this.getMainContactToAddress();
        return (mainSite as any).getAddress();
    }

    public async hasMainAddressDefined(): Promise<boolean> {
        const mainAddress = await this.getMainAddress();
        return mainAddress.isFilled();
    }

    public async getInvoiceContact(): Promise<Contact> {
        const invoiceSite = await this.getInvoiceContactToAddress();

        if (!invoiceSite) { // no invoice contact specified, fall back to main contact
            return this.getMainContact();
        }

        return (invoiceSite as any).getContact();
    }

    public async getInvoiceAddress(): Promise<Address> {
        const invoiceSite = await this.getInvoiceContactToAddress();

        if (!invoiceSite) { // no invoice contact specified, fall back to main contact
            return this.getMainAddress();
        }

        return (invoiceSite as any).getAddress();
    }

    public async getRegisteredAddress(): Promise<Address> {
        const registeredSite = await this.getRegisteredContactToAddress();

        if (!registeredSite) { // no invoice contact specified, fall back to main contact
            return this.getMainAddress();
        }

        return registeredSite.getAddress();
    }

    public async getSiteByContactId(contactId: number): Promise<ContactToAddress> {
        // Check if contact is an alternate contact
        let contacts = await this.getAllContact({
            filters: [{
                property: 'alternate_contact_id',
                operator: '=',
                value: contactId,
            }],
        });
        /**
         * This is a bit counterintuitive, but the problem is, that getById and get does not support remote filtering,
         * so we always load "all" items, with filtering to one item only.
         */
        let [contact] = contacts || [];
        // If not then get the contact
        if (!contact) {
            contacts = await this.getAllContact({
                filters: [{
                    property: 'id',
                    operator: '=',
                    value: contactId,
                }],
            });
            [contact] = contacts || [];
        }
        const contactToAddress = await contact?.getContactToAddress();

        return contactToAddress;
    }

    //
    // Methods for customer contact / address retrieval with legacy database in mind
    //
    // todo: handle legacy customers
    public async getMainContactToAddress(): Promise<ContactToAddress> {
        let mainSite = await this.getMainSite();

        if (!mainSite && !this.disposable) {
            mainSite = await this.setMainSite({});
            //
            // Set main contact
            //
            const contacts = await this.getAllContact();
            const mainContact = contacts && contacts.length > 0 ? contacts[0] : null;

            if (mainContact) {
                //
                // Make sure contact we have all the required methods and create missing ones
                //
                const contactMethods = await mainContact.getAllContactMethod();
                const contactPhone = contactMethods.find(contactMethod => contactMethod.data.contactmethodtype_id === constants.MESSAGES.TYPES.SMS);

                if (!contactPhone) {
                    await mainContact.addContactMethod({
                        contactmethodtype_id: constants.MESSAGES.TYPES.SMS,
                        is_regular: true,
                    });
                }

                const contactEmail = contactMethods.find(contactMethod => contactMethod.data.contactmethodtype_id === constants.MESSAGES.TYPES.EMAIL);

                if (!contactEmail) {
                    await mainContact.addContactMethod({
                        contactmethodtype_id: constants.MESSAGES.TYPES.EMAIL,
                        is_regular: true,
                    });
                }
                //
                // Add main contact to the main site
                //
                await mainSite.setContact(mainContact);
            }
            await this.initialiseMainAddress();
        } else if (mainSite && !await mainSite.getAddress()) {
            await this.initialiseMainAddress();
        }

        return mainSite;
    }

    private async initialiseMainAddress(): Promise<void> {
        const mainSite = await this.getMainSite();
        const mainAddress = await this.getDefaultMainAddressData();
        await mainSite.setAddress(mainAddress);
    }

    private async getDefaultMainAddressData() {
        const registeredAddress = await this.findRegisteredAddress();

        if (!registeredAddress) {
            return this.getDefaultAddressData();
        }

        return registeredAddress;
    }

    private async findRegisteredAddress(): Promise<Address> {
        const addresses = await this.getAllAddress();
        return addresses.find(address => address.data.is_registered);
    }

    private async getDefaultAddressData() {
        const company = await this.getCompany();
        const country = await company.findCountry();
        return {
            country: country.data.code,
            is_registered: true,
            customer_id: this.data.id,
        };
    }

    public async getInvoiceContactToAddress(): Promise<ContactToAddress> {
        let invoiceSite = await this.getInvoiceSite();

        if (!invoiceSite) {
            //
            // Set invoice address
            //
            const addresses = await this.getAllAddress();
            const invoiceAddress = addresses.find(address => address.data.is_invoice);

            if (invoiceAddress) {
                invoiceSite = await this.setInvoiceSite({});
                await invoiceSite.setAddress(invoiceAddress);
                //
                // Set invoice contact
                //
                const invoiceContact = await this.getMainContact();
                // todo: clone invoice contact to have duplicate record??
                await invoiceSite.setContact(invoiceContact);
            }
        }
        return invoiceSite;
    }

    public async getRegisteredContactToAddress() {
        return this.getRegisteredSite();
    }

    public static get allowedAssociations(): CustomerAssociations {
        return {
            company: {
                instance: Company,
                entity: CompanyEntity,
                key: 'company',
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().company_id,
                },
            },

            contact: {
                instance: Contact,
                entity: ContactEntity,
                key: 'contact',
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },

            address: {
                key: 'address',
                instance: Address,
                entity: AddressEntity,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },

            registeredSite: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: constants.CONTACT_TO_ADDRESS.TYPE.REGISTERED,
                },
            },

            mainSite: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: constants.CONTACT_TO_ADDRESS.TYPE.MAIN,
                },
            },

            invoiceSite: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                single: true,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: constants.CONTACT_TO_ADDRESS.TYPE.INVOICE,
                },
            },

            site: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: constants.CONTACT_TO_ADDRESS.TYPE.SITE,
                },
            },

            contactToAddress: {
                key: 'contactToAddress',
                instance: ContactToAddress,
                entity: ContactToAddressEntity,
                cascadeDelete: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            cisRate: {
                key: 'cisRate',
                instance: CisRate,
                entity: CisRateEntity,
                single: true,
                cascadeDelete: false,
                condition: {
                    id: this.Entity.getForeignFieldSymbols().cis_rate_id,
                },
            },
            document: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            quote: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                condition: {
                    type: 1,
                    confirmed: true,
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            purchaseOrder: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                condition: {
                    type: constants.DOCUMENT.TYPES_VALUES.PURCHASE_ORDER,
                    confirmed: true,
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            supplierInvoice: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                condition: {
                    type: constants.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE,
                    confirmed: true,
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            invoice: {
                key: 'document',
                instance: Document,
                entity: DocumentEntity,
                condition: {
                    type: 0,
                    confirmed: true,
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            formDocument: {
                key: 'formDocument',
                instance: FormDocument,
                entity: FormDocumentEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            job: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            autoJob: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                single: true,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    is_autocreated: true,
                    status: constants.STATUS.ID.JOB_OPEN,
                },
            },
            nonAutoJob: {
                key: 'job',
                instance: Job,
                entity: JobEntity,
                cascadeDelete: false,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    is_autocreated: false,
                    status: constants.STATUS.ID.JOB_OPEN,
                },
            },
            whiteLabelToCustomer: {
                key: 'whiteLabelToCustomer',
                instance: WhiteLabelToCustomer,
                entity: WhiteLabelToCustomerEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            payment: {
                key: 'paymentsForCustomer',
                instance: PaymentsForCustomer,
                entity: PaymentsForCustomerEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            actionsForCustomer: {
                key: 'actionsForCustomer',
                instance: ActionsForCustomer,
                entity: ActionsForCustomerEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            appointmentForCustomer: {
                key: 'actionsForCustomer',
                instance: ActionsForCustomer,
                entity: ActionsForCustomerEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: ACTION_CONST.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                },
            },
            appointment: {
                key: 'actionsForCustomer',
                instance: ActionsForCustomer,
                entity: ActionsForCustomerEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                    type: ACTION_CONST.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                },
            },
            message: {
                key: 'message',
                instance: Message,
                entity: MessageEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            customerMessage: {
                key: 'customerMessage',
                instance: CustomerMessage,
                entity: CustomerMessageEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
            lastMessageInThread: {
                key: 'lastMessageInThread',
                instance: LastMessageInThread,
                entity: LastMessageInThreadEntity,
                condition: {
                    customer_id: this.Entity.getFieldSymbols().id,
                },
            },
        };
    }

    protected async initDefaultAssociatedItems(): Promise<void> {
        //
        // Automatically add main site to new customers
        //
        await this.setMainSite({});
        //
        // Default to the county's default rate
        //
        this.data.cis_rate_id = await CisRate.getDefaultRateId(this);
        return null;
    }

    protected async validateCustomRules(params: any): Promise<ValidationResult> {
        const validationResult = await super.validateCustomRules(params);

        const { currentUser } = params.globalObjects;

        const currentUserCanEditCustomers = await currentUser.hasRole(constants.ROLES.PERMISSIONS.CAN_EDIT_CUSTOMERS);
        if (!currentUserCanEditCustomers) {
            validationResult.errors.push(constants.VALIDATION.CODES.CUSTOMER.NO_CREATE_PERMISSION);
        }

        if (!this.data.is_customer && !this.data.is_supplier) {
            validationResult.errors.push(constants.VALIDATION.CODES.CUSTOMER.INVALID_TYPE);
        }

        if (this.data.is_customer && this.data.is_supplier && this.data.cis_enabled) {
            validationResult.errors.push(constants.VALIDATION.CODES.CUSTOMER.INVALID_TYPE_AND_CIS);
        }
        //
        // CIS enabled validate UTR
        //
        if (this.data.cis_enabled && this.data.is_supplier && (!this.data.utr || this.data.utr === '')) {
            const error = _.cloneDeep(constants.VALIDATION.CODES.CUSTOMER.INVALID_UTR);
            error.complexObject = this;
            validationResult.errors.push(error);
        }

        const mainSite = await this.getMainSite();
        const mainContact = await mainSite.getContact();
        if ((!this.data.company || this.data.company.trim() === '') && (!mainContact.data.lastname && !mainContact.data.firstname)) {
            validationResult.errors.push(constants.VALIDATION.CODES.CUSTOMER.INVALID_CONTACT_NAMES);
        }

        return validationResult;
    }

    public async syncWhatsAppContacts() {
        await Bluebird.each(this.getAllContact(), async contact => {
            const whatsAppContactMethod = await contact.findWhatsAppContactMethod();
            const phoneForWhatsApp = await contact.findPhoneForWhatsApp();
            if (whatsAppContactMethod && phoneForWhatsApp) {
                const whatsAppData = phoneForWhatsApp.data.getPureDataValues();
                whatsAppContactMethod.data.value = whatsAppData.value;
            }
        });
    }

    public async getFullName(forceFirstLastName: boolean = false, forceCompanyName: boolean = false): Promise<string> {
        let fullName = '';

        const mainContact = await this.getMainContact();

        if (mainContact) {
            fullName = await mainContact.getFullName();
        }

        const companyName = this.data.company && this.data.company.trim();

        if ((!forceFirstLastName && fullName === '')
            || (forceCompanyName && companyName)) {
            fullName = companyName;
        }

        return fullName;
    }

    /**
     * Get a title to be displayed. It is the company name if specified otherwise
     * the main contact's full name
     *
     * @returns {Promise<string>}
     */
    public async getTitle(): Promise<string> {
        const fullName = this.data.company;
        if (fullName && fullName.trim() !== '') {
            return fullName;
        }
        const mainContact = await this.getMainContact();

        if (mainContact) {
            return mainContact.getFullName();
        }

        return '';
    }

    /**
     * Append the existing note text with the passed in note separated by new
     * line if we already have note text stored
     *
     * @param {string} newNote
     */
    public appendNote(newNote: string): void {
        this.data.notes = this.data.notes ? `${this.data.notes}\r\n${newNote}` : newNote;
    }

    /**
     * Create a new project with the specified description for this customer
     *
     * @param {string} description
     * @param {number} siteId
     * @param {boolean} isAutoCreated
     * @returns {any}
     */
    public addJobWithDescription(description: string, siteId: number, isAutoCreated: boolean = true) {
        const newJobData = {
            description,
            status: 0,
            is_autocreated: isAutoCreated,
            site_id: siteId,
        };
        return this.addJob(newJobData);
    }

    /**
     *  Get the first auto created project's ID for this customer
     *  In case of new installations there may be more than one auto created job
     *  however in registrations after this release all customers may have only a
     *  single auto created project
     *
     * @returns {number}
     */
    async getAutoJobId(): Promise<number> {
        const recAutoJob = await this.getAutoJob();
        return recAutoJob !== null ? recAutoJob.data.id : null;
    }

    async usesJobs(): Promise<boolean> {
        const nonAutoCreated = await this.getAllNonAutoJob();
        return nonAutoCreated && nonAutoCreated.length !== 0;
    }

    async getFilteredSiteList(searchString: string): Promise<ContactToAddress[]> {
        const sites = (await this.getAllContactToAddress())
            .filter(site => site.data.type === constants.CONTACT_TO_ADDRESS.TYPE.INVOICE
                || site.data.type === constants.CONTACT_TO_ADDRESS.TYPE.SITE);
        if (!searchString) {
            return sites;
        }
        const filteredList = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (const site of this.filterSites(sites, async sitePredicate => {
            if (sitePredicate.prepareSearchField) {
                // For extra safety, most likely only a unit-test issue
                await sitePredicate.prepareSearchField();
            }
            return sitePredicate.data[Entity.searchFieldSymbol].indexOf(searchString) >= 0;
        })) {
            filteredList.push(site);
        }
        return filteredList.length > 0 ? filteredList : sites;
    }

    async* filterSites(sites, predicate, limit = 10) {
        let results = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const site of sites) {
            if (results >= limit) {
                return;
            }
            // eslint-disable-next-line no-await-in-loop
            if (await predicate(site)) {
                results += 1;
                yield site;
            }
        }
    }

    async prepareSiteSearch() {
        await Bluebird.map(this.getAllContactToAddress(), async (site: ContactToAddress) => site.prepareSearchField());
    }

    async prepareContactListFiltering(): Promise<void> {
        await Bluebird.map(this.getAllContact(), async (contact: Contact) => {
            contact.data.suppressEvents();
            //
            // Setup search symbol list
            //
            contact.data[Entity.searchFieldSymbol] = (await contact.getSearchableFields()).join(' ').toLowerCase();
            //
            // Filter relevant contacts
            //
            const fullName = await contact.getFullName();
            const isContactable = await contact.isContactable(['SMS', 'EMAIL', 'ELECTRONIC']);
            contact.data[Entity.listFilterSymbol] = fullName !== '' && isContactable;
            contact.data.unSuppressEvents();
        });
    }

    public async getEmailableContactMethods(): Promise<ContactMethod[]> {
        const contacts: Contact[] = await this.getAllContact();

        let allEmailableContactMethods = [];
        await Bluebird.each(contacts, async contact => {
            const emailableContactMethods = await contact.getEmailableContactMethods();
            allEmailableContactMethods = [...allEmailableContactMethods, ...(emailableContactMethods || [])];
        });

        return allEmailableContactMethods;
    }

    public async getContactMethods(): Promise<ContactMethod[]> {
        const contacts: Contact[] = await this.getAllContact();
        let allContactMethods = [];
        await Bluebird.each(contacts, async contact => {
            const contactMethods = await contact.getAllContactMethod();
            allContactMethods = [...allContactMethods, ...(contactMethods || [])];
        });
        return allContactMethods;
    }

    public async getContactMethodsByEmail(email): Promise<ContactMethod[]> {
        const contactMethods = await this.getEmailableContactMethods();
        return Bluebird.filter(contactMethods, async contactMethod => await contactMethod.data.value === email);
    }

    public isDummyCustomerForExpenses(): boolean {
        return this.data.is_nosupplier;
    }

    public async getTemplateData(defaultSite: ContactToAddress = null, plot: string = null) : Promise<CustomerTemplateData> {
        if (this.isDummyCustomerForExpenses()) {
            return { ...this.data.getPureDataValues() };
        }

        const site = defaultSite || await this.getMainSite();
        const contact = await site.getContact();
        const address = await site.getAddress();
        const registeredAddress = await this.getRegisteredAddress();
        const country = await registeredAddress.getCountry();
        const data = {
            ...this.data.getPureDataValues(),
            fullName: await contact.getFullName(),
            contacts: contact ? [await contact.getTemplateData()] : [],
            address: address ? [await address.getTemplateData(plot)] : [],
            registeredAddress: await Formatter.stringifyAddress({
                address: registeredAddress.data.getPureDataValues(),
                ...(country ? { country: country.data.name } : {}),
            }),
            mapAddress: await this.getAddressForMap(),
        };

        return data;
    }

    public async getAddressForMap(site: ContactToAddress = null): Promise<string> {
        return this.getAddressForDiary(site);
    }

    public async getAddressForDiary(site: ContactToAddress = null, plot: string = null): Promise<string> {
        const address = await this.findAddress(site);
        return address.stringifyAddress(plot, true);
    }

    private async findAddress(site: ContactToAddress = null): Promise<Address> {
        return site
            ? (site as any).getAddress()
            : this.getMainAddress();
    }

    public async isLinked(invitationHash): Promise<boolean> {
        const customerLinking = await this.loadGenericData(<typeof ConnectedData>(Customer as unknown), {
            external_company_id: this.data.external_company_id,
            invitation_hash: invitationHash,
        });

        return customerLinking && customerLinking.length > 0;
    }

    public async isUkOrIOM(): Promise<boolean> {
        const address = await this.getMainAddress();
        const country = await (address as any).getCountry();
        if (country) {
            return country.isUkOrIOM();
        }
        return false;
    }

    public async productCategoryRequired(): Promise<boolean> {
        const company = await this.getCompany();
        if (await company.requiresCheckForMandatoryProductCategory()) {
            return !await this.isUkOrIOM();
        }
        return false;
    }

    public async findCountryId(): Promise<number> {
        const address = await this.getMainAddress();
        return address.findCountryId();
    }

    public isSyncedWithAccountingSystem() {
        return this.data.accounting_reference && this.data.accounting_reference !== '' && this.data.accounting_reference !== constants.INTEGRATION.ACCOUNTING_REFERENCE_NO_SYNC;
    }

    public async isWhiteLabelCustomer(): Promise<boolean> {
        const wlToCustomer = await this.getAllWhiteLabelToCustomer();
        return wlToCustomer && wlToCustomer.length === 1;
    }

    public async getWhiteLabel(): Promise<WhiteLabel> {
        const wlToCustomer = await this.getAllWhiteLabelToCustomer();
        if (!wlToCustomer || wlToCustomer.length !== 1) {
            return null;
        }
        return wlToCustomer[0].getWhiteLabel();
    }

    public async validateAccountNumber(): Promise<boolean> {
        const whiteLabel = await this.getWhiteLabel();
        if (!whiteLabel || !whiteLabel.requiresAccountValidation()) {
            return true;
        }
        const validationRule = new RegExp(whiteLabel.data.account_number_validation_rule, 'i');
        return validationRule.test(this.data.account_name);
    }

    public async isSimpleModeAvailable(): Promise<boolean> {
        const sites = await this.getAllContactToAddress();
        const addresses = await this.getAllAddress();
        const contacts = await this.getAllContact();
        const contactMethods = await this.getContactMethods();
        const hasDescription = await this.hasMainContactDescriptionDefined();

        return sites && sites.length === 1
            && contacts && contacts.length === 1
            && addresses && addresses.length === 1
            && contactMethods && contactMethods.length === 2
            && !hasDescription
            && (!this.data.notes || stripTags(this.data.notes) === '')
            && !this.data.reference
            && !this.data.cis_enabled;
    }
}

export default Customer;
